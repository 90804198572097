import { FunctionalsDraftType } from "src/types";

export function resetLocalStateProperty(
	draft: FunctionalsDraftType,
	prev: FunctionalsDraftType,
	localStateProperty: string,
) {
	if (localStateProperty !== null) {
		if (localStateProperty === "diet") {
			draft[localStateProperty] = {
				...prev[localStateProperty]!,
				questions: prev[localStateProperty]!.questions.map(question => {
					return {
						...question,
						value: 0,
					};
				}),
			};
		}
		else if (localStateProperty === "KATZADL") {
			draft[localStateProperty] = {
				...prev[localStateProperty]!,
				total: 0,
				activities: prev[localStateProperty]!.activities.map(activity => {
					return {
						...activity,
						checked: false,
					};
				}),
			};
		}
		else if (localStateProperty === "LBIADL") {
			draft[localStateProperty] = {
				...prev[localStateProperty]!,
				total: 0,
				activities: prev[localStateProperty]!.activities.map(activity => {
					return {
						...activity,
						selectedvalue: 0,
						options: activity.options.map(option => {
							return {
								...option,
								checked: false,
							};
						}),
					};
				}),
			};
		}
		else if (localStateProperty === "physicalactivity") {
			draft[localStateProperty] = {
				...prev[localStateProperty]!,
				questions: prev[localStateProperty]!.questions.map(question => {
					return {
						...question,
						value: 0,
					};
				}),
			};
		}
		else if (localStateProperty === "socialactivity") {
			draft[localStateProperty] = {
				...prev[localStateProperty]!,
				questions: prev[localStateProperty]!.questions.map(question => {
					return {
						...question,
						value: 0,
					};
				}),
			};
		}
	}
	else {
		draft[localStateProperty] = null;
	};
};

export function resetLocalState(prev: FunctionalsDraftType) {
	const draft = {} as FunctionalsDraftType;
	draft.checked = prev.checked;
	draft.id = prev.id;
	resetLocalStateProperty(draft, prev, "diet");
	resetLocalStateProperty(draft, prev, "KATZADL");
	resetLocalStateProperty(draft, prev, "LBIADL");
	resetLocalStateProperty(draft, prev, "physicalactivity");
	resetLocalStateProperty(draft, prev, "socialactivity");
	resetLocalStateProperty(draft, prev, "socialdrughistory");
	resetLocalStateProperty(draft, prev, "socialdeterminants");

	return draft;
};
