import { MouseEvent } from 'react';
import {
	FormControl,
	ToggleButtonGroup,
	ToggleButton,
} from '@mui/material';

import { GroupedScreeningIndivType, RadioOptionsIndivType, SingleScreeningIndivType } from 'src/types';

interface IoToggleButtonGroupProps {
	screeningOrDiagnostic: SingleScreeningIndivType | RadioOptionsIndivType;
	group?: GroupedScreeningIndivType;
	onHandleChange: (
		event: MouseEvent<HTMLElement, globalThis.MouseEvent>,
		value: string,
		id: string,
		parentId?: string,
	) => void,
};

export function IoToggleButtonGroup({
	screeningOrDiagnostic,
	group,
	onHandleChange,
}: IoToggleButtonGroupProps) {
	return (
		<FormControl
			size="small"
		>
			<ToggleButtonGroup
				size="small"
				value={screeningOrDiagnostic.toggleButtonValue}
				exclusive
				onChange={(event, value) =>
					onHandleChange(event, value, screeningOrDiagnostic.id, group?.id)}
			>
				{screeningOrDiagnostic.toggleOptions
					.map(tOption => {
						return (
							<ToggleButton
								key={tOption.id}
								value={tOption.id}
							>
								{tOption.id}
							</ToggleButton>
						);
					})
				}
			</ToggleButtonGroup>
		</FormControl>
	);
};
