import { ChangeEvent, useState } from "react";
import {
	Checkbox,
	FormControlLabel,
	Radio,
} from "@mui/material";

interface IoFormControlLabelProps {
	id?: string;
	radio?: boolean;
	which?: string;
	onExternalChange?: (
		event: ChangeEvent<HTMLInputElement>,
		checked: boolean,
		id: string,
		which?: string,
	) => void;
	disabled?: boolean;
};

export function IoFormControlLabel({
	id = "",
	radio = false,
	which,
	onExternalChange,
	disabled = false
}: IoFormControlLabelProps) {
	const [checked, setChecked] = useState(false);

	const onChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
		if (onExternalChange) {
			onExternalChange(event, checked, id, which ? which : undefined);
		};
		setChecked(checked);
	};

	return (
		<FormControlLabel
			control={radio ?
				<Radio
				/> :
				<Checkbox
					inputProps={{
						"aria-label": "controlled"
					}}
					checked={checked}
					onChange={onChange}
					name={id}
					size="small"
					disabled={disabled}
				// which={which}
				/>
			}
			label={id}
		/>
	);
};
