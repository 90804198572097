import dayjs from "dayjs";
import { createContext, useContext, Dispatch, ReactNode, useEffect } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import { GroupedScreeningDraftType } from "src/types";
import { ZodGroupedScreeningDraftType } from "src/types_zod";

import base from 'src/public/screenings/groupedscreenings.json';
import { modifyDateAccordingToTest } from "src/io/wrappers/IoLinkedDates";

parseZodReaderFriendly(ZodGroupedScreeningDraftType, base);

const init = base as GroupedScreeningDraftType;

export const GroupScreeningsContext = createContext<GroupedScreeningDraftType | null>(null);
export const GroupScreeningsDispatchContext = createContext<Dispatch<GroupedScreeningActionType> | null>(null);

export function useGroupScreenings() {
	return useContext(GroupScreeningsContext)!;
};

export function useGroupScreeningsDispatch() {
	return useContext(GroupScreeningsDispatchContext)!;
};

export interface GroupedScreeningInitActionType {
	type: string;
	value: GroupedScreeningDraftType;
};

export interface GroupedScreeningCheckboxActionType {
	type: string,
	id: string,
	value: boolean,
};

export interface GroupedScreeningLinkCheckedInGroupActionType {
	type: string,
	id: string,
	value: boolean,
	parent: string;
};

export interface GroupedScreeningScreeningInGroupActionType {
	type: string;
	id: string;
	parent: string;
};

export interface GroupedScreeningDateReceivedinGroupActionType {
	type: string;
	id: string;
	value: string | null;
	parent: string;
};

export interface GroupedScreeningsToggleDateDueModifierActionType {
	type: string;
	id: string;
	value: string;
	parent: string;
};

export interface GroupedScreeningDateDueInGroupActionType {
	type: string;
	id: string;
	value: string | null;
	parent: string;
};

export type GroupedScreeningActionType =
	GroupedScreeningInitActionType
	| GroupedScreeningCheckboxActionType
	| GroupedScreeningLinkCheckedInGroupActionType
	| GroupedScreeningScreeningInGroupActionType
	| GroupedScreeningDateReceivedinGroupActionType
	| GroupedScreeningsToggleDateDueModifierActionType
	| GroupedScreeningDateDueInGroupActionType;

function groupScreeningsReducer(draft: Draft<GroupedScreeningDraftType>, action: GroupedScreeningActionType) {
	switch (action.type) {
		case 'initialize': {
			const narrowedAction = action as GroupedScreeningInitActionType;
			return narrowedAction.value;
		}
		case 'setCheckbox': {
			const narrowedAction = action as GroupedScreeningCheckboxActionType;

			const group = draft.filter(n => n.id === narrowedAction.id)[0];
			group.checked = narrowedAction.value;
			group.radioButtonValue = null;
			group.radioOptions = group.radioOptions.map(screening => {
				return {
					...screening,
					toggleButtonValue: screening.toggleOptions[0].id,
					dateReceived: null,
					dateDue: null,
				}
			});
			break;
		}
		case 'setLinkChecked': {
			const narrowedAction = action as GroupedScreeningLinkCheckedInGroupActionType;

			const group = draft.filter(n => n.id === narrowedAction.parent)[0];
			const screening = group.radioOptions.filter(n => n.id === narrowedAction.id)[0];

			screening.linkChecked = narrowedAction.value;
			break;
		}
		case 'setScreeningInGroup': {
			const narrowedAction = action as GroupedScreeningScreeningInGroupActionType;

			const group = draft.filter(n => n.id === narrowedAction.parent)[0];
			group.radioButtonValue = narrowedAction.id;

			const screening = group.radioOptions.filter(m => m.id === narrowedAction.id)[0]
			screening.toggleButtonValue = screening.toggleOptions[0].id;

			screening.dateReceived = null;
			screening.dateDue = null;

			const notChoice = group.radioOptions.filter(m => m.id !== narrowedAction.id)[0];
			notChoice.toggleButtonValue = screening.toggleOptions[0].id;

			notChoice.dateReceived = null;
			notChoice.dateDue = null;

			break;
		}
		case 'setDateReceivedinGroup': {
			const narrowedAction = action as GroupedScreeningDateReceivedinGroupActionType;

			const group = draft.filter(n => n.id === narrowedAction.parent)[0];
			const screening = group.radioOptions.filter(n => n.id === narrowedAction.id)[0];

			screening.dateReceived = narrowedAction.value;
			break;
		}
		case 'toggleDateDueModifier': {
			const narrowedAction = action as GroupedScreeningsToggleDateDueModifierActionType;

			const group = draft.filter(n => n.id === narrowedAction.parent)[0];
			const screening = group.radioOptions.filter(m => m.id === narrowedAction.id)[0];
			screening.toggleButtonValue = narrowedAction.value;
			if (screening.toggleButtonValue === null && screening.dateReceived !== null) {
				const moddedVal = modifyDateAccordingToTest(narrowedAction.parent, dayjs(screening.dateReceived), "screening");
				const val = moddedVal.format('MM/DD/YYYY')
				screening.dateDue = val;
			}
			else {
				screening.dateDue = null;
			};

			break;
		}
		case 'setDateDueInGroup': {
			const narrowedAction = action as GroupedScreeningDateDueInGroupActionType;

			const group = draft.filter(n => n.id === narrowedAction.parent)[0];
			const screening = group.radioOptions.filter(n => n.id === narrowedAction.id)[0];
			screening.dateDue = narrowedAction.value;
			break;
		}
		default: {
			return draft;
		}
	};
};

interface GroupScreeningsPhysicianProps {
	children: ReactNode,
	testingInit?: GroupedScreeningDraftType,
};

export function GroupScreeningsProvider({
	children,
	testingInit,
}: GroupScreeningsPhysicianProps) {
	const [groupScreenings, dispatch] = useImmerReducer(groupScreeningsReducer, testingInit ? testingInit : init as GroupedScreeningDraftType);

	// useEffect(() => {
	// 	let ignore = false;
	// 	import(
	// 		/* webpackChunkName: "groupedscreenings" */
	// 		`../../public/screenings/groupedscreenings.json`
	// 	)
	// 		.then(({ default: myJson }: { default: GroupedScreeningDraftType }) => {
	// 			if (!ignore) {
	// 				dispatch({
	// 					type: "initialize",
	// 					value: myJson
	// 				});
	// 			};
	// 		})
	// 		.catch(err => console.log("err: ", err));

	// 	return () => {
	// 		ignore = true;
	// 	};
	// }, [dispatch]);

	return (
		<GroupScreeningsContext.Provider value={groupScreenings}>
			<GroupScreeningsDispatchContext.Provider value={dispatch}>
				{children}
			</GroupScreeningsDispatchContext.Provider>
		</GroupScreeningsContext.Provider>
	);
};
