import dayjs from 'dayjs';
import { ChangeEvent, useCallback } from 'react';
import {
	Collapse,
	Stack,
} from '@mui/material';

import { useBothVaccinesDispatch } from 'src/components/physical/vaccines/BothVaccinesContext';
import { BothVaccineIndivType } from 'src/types';

import { IoFormControlLabel } from 'src/io/wrappers/IoFormControlLabel';
import { IoLinkedDates } from 'src/io/wrappers/IoLinkedDates';
import { ExternalIoFormControlLabel } from 'src/io/wrappers/ExternalIoFormControlLabel';

interface BothVaccineProps {
	vaccine: BothVaccineIndivType;
};

export function BothVaccine({
	vaccine,
}: BothVaccineProps) {
	const dispatch = useBothVaccinesDispatch();

	const handleCheckbox = useCallback(
		(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
			dispatch({
				type: "setCheckbox",
				id: event.target.name,
				value: checked,
			});
		}, [dispatch]
	);

	const handleDateReceived = useCallback((
		value: string | null,
		id: string,
		parent: string,
	) => {
		dispatch({
			type: "setDateReceived",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);

	const handleDateDue = useCallback((
		value: string | null,
		id: string,
		parent: string,

	) => {
		dispatch({
			type: "setDateDue",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);

	const handleLinkChecked = useCallback((
		value: boolean,
		id: string,
		parent: string,
	) => {
		dispatch({
			type: "setLinkChecked",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);

	return (
		<Stack
			direction="row"
			key={vaccine.id}
		>
			<ExternalIoFormControlLabel
				id={vaccine.id}
				checked={vaccine.checked}
				onExternalChange={handleCheckbox}
			/>
			<Collapse
				orientation="horizontal"
				in={vaccine.checked}
				unmountOnExit
			>
				<IoLinkedDates
					vaccine={vaccine}
					externalDateReceived={dayjs(vaccine.dateReceived)}
					externalDateDue={dayjs(vaccine.dateDue)}
					externallinkChecked={vaccine.linkChecked}
					handleDateReceived={handleDateReceived}
					handleDateDue={handleDateDue}
					handleLinkChecked={handleLinkChecked}
				/>
			</Collapse>
		</Stack>
	);
};
