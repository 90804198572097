import { Dayjs } from "dayjs";
import { useMemo } from "react";

// import { Stack } from "@mui/material";
import { DateField, DateValidationError } from "@mui/x-date-pickers";
import { FieldChangeHandlerContext } from "@mui/x-date-pickers/internals";
import { IoInputProps } from "src/types";

interface ExternalIoDatePickerProps {
	label: string;
	value: Dayjs | null;
	readOnly?: boolean;
	setExternalState: (
		value: { value: Dayjs | null, validationError: string | null },
	) => void;
	id?: string;
};

export function ExternalIoDatePicker({
	label,
	value,
	readOnly,
	setExternalState,
	id,
}: ExternalIoDatePickerProps) {
	const inputProps: IoInputProps = useMemo(() => {
		return {
			"data-testid": `${id ? id : "lolz"}-datepicker`,
		};
	}, [id]);

	const onChange = (value: Dayjs | null, context: FieldChangeHandlerContext<DateValidationError>) => {
		setExternalState({
			value: value,
			validationError: context.validationError,
		});
	};

	return (
		// <Stack spacing={3}>
			<DateField
				inputProps={inputProps}
				label={label}
				value={value}
				size="small"
				readOnly={readOnly ? readOnly : false} // logically denotes readonly
				onChange={onChange}
				slotProps={{
					textField: {
						disabled: readOnly ? readOnly : false // visually denotes readonly
					}
				}}
			/>
		// </Stack>
	);
};
