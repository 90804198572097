import { MouseEvent, useCallback, ChangeEvent } from "react";
import {
	FormControl,
	ToggleButtonGroup,
	ToggleButton,
	Collapse,
} from "@mui/material";

import { useSocialDrugHistoryDispatch } from "./SocialDrugHistoryContext";
import { IoFormControlLabel } from "src/io/wrappers/IoFormControlLabel"
;
import { SocialDrugHistoryIllegalIndivType } from "src/types";
import { ExternalIoFormControlLabel } from "src/io/wrappers/ExternalIoFormControlLabel";

interface SocialDrugHistoryQuestionIllegalProps {
	questionsGroup: SocialDrugHistoryIllegalIndivType; // used to be a subtype when subtypes was the unneccessary level between parent structure and child data
};

export function SocialDrugHistoryQuestionIllegal({
	questionsGroup,
}: SocialDrugHistoryQuestionIllegalProps) {
	const dispatchSocialDrugHistory = useSocialDrugHistoryDispatch();

	// useEffect(() => {
	// 	console.log(questionsGroup);
	// }, [questionsGroup])

	const onHandleToggleButtonGroupChange = useCallback((
		event: MouseEvent<HTMLElement, globalThis.MouseEvent>,
		value: string,
		id: string,
	) => {
		dispatchSocialDrugHistory({
			type: "setToggleButtonGroup",
			id: id,
			value: value,
		});
	},
		[
			dispatchSocialDrugHistory
		]
	);

	const handleCheckbox = useCallback((
		_event: ChangeEvent<HTMLInputElement>,
		checked: boolean,
		id: string,
		_which?: string,
	) => {
		dispatchSocialDrugHistory({
			type: "setCheckbox",
			parentId: questionsGroup.id,
			id: id,
			value: checked,
		});
	}, [
		dispatchSocialDrugHistory,
		questionsGroup.id,
	]);

	return (
		<>
			<FormControl
				size="small"
			>
				<ToggleButtonGroup
					size="small"
					value={questionsGroup.toggleButtonValue}
					exclusive
					onChange={(event, value) =>
						onHandleToggleButtonGroupChange(event, value, questionsGroup.id)}
				>
					{questionsGroup.toggleOptions
						.map(tOption => {
							return (
								<ToggleButton
									key={tOption.id}
									value={tOption.id}
								>
									{tOption.id}
								</ToggleButton>
							);
						})
					}
				</ToggleButtonGroup>
			</FormControl>
			<Collapse
				in={questionsGroup.toggleButtonValue !== "NEVER USED"}
				timeout="auto"
				unmountOnExit
			>
				<ExternalIoFormControlLabel
					id={questionsGroup.attemptedrehab.id}
					checked={questionsGroup.attemptedrehab.value}
					onExternalChange={handleCheckbox}
					which="checked"
				/>
			</Collapse>
			<Collapse
				in={questionsGroup.toggleButtonValue !== "NEVER USED"}
				timeout="auto"
				unmountOnExit
			>
				<ExternalIoFormControlLabel
					id={questionsGroup.hospitalized.id}
					checked={questionsGroup.hospitalized.value}
					onExternalChange={handleCheckbox}
					which="checked"
				/>
			</Collapse>
		</>
	);
};
