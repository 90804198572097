import { MouseEvent } from "react";
import {
	Stack,
	Button,
} from "@mui/material";

import { useDemographics } from "src/components/administrative/demographics/DemographicsContext";
import { useAppointments } from "src/components/administrative/appointments/AppointmentsContext";
import { usePhysicians } from "src/components/administrative/physicians/PhysiciansContext";
import { useMedications } from "src/components/medications/MedicationsContext";
import { useMedicationAllergies } from "src/components/medications/MedicationAllergiesContext";
import { usePhysicalInformation } from "src/components/physical/physicalinformation/PhysicalInformationContext";
import { usePneumococcalVaccine } from "src/components/physical/vaccines/PneumococcalVaccine/PneumococcalVaccineContext";
import { useBothVaccines } from "src/components/physical/vaccines/BothVaccinesContext";
import { useDosesVaccines } from "src/components/physical/vaccines/DosesVaccinesContext";
import { useOnlyVaccines } from "src/components/physical/vaccines/OnlyVaccinesContext";
import { useGroupScreenings } from "src/components/screenings/GroupScreeningsContext";
import { useSingleScreenings } from "src/components/screenings/SingleScreeningsContext";
import { useToggledScreenings } from "src/components/screenings/ToggledScreeningsContext";
import { useFunctionals } from "src/components/functionals/FunctionalsContext";
import { useConditions } from "src/components/conditionsanddiseases/conditions/ConditionsContext";
import { useSingleDiagnostics } from "src/components/conditionsanddiseases/diagnostics/SingleDiagnosticsContext";
import { useSocialDrugHistory } from "src/components/conditionsanddiseases/socialdrughistory/SocialDrugHistoryContext";
import { useSocialDeterminants } from "src/components/socialdeterminants/SocialDeterminantsContext";

interface CCPButtonProps {
	// mode: string;
};

// eslint-disable-next-line no-empty-pattern
export function CCPButton({
	// mode,
}: CCPButtonProps) {
	const demographics = useDemographics();
	const appointments = useAppointments();
	const physicians = usePhysicians();
	const medications = useMedications();
	const medicationAllergies = useMedicationAllergies();
	const physicalInformation = usePhysicalInformation();
	const pneumococcalVaccine = usePneumococcalVaccine();
	const allBothVaccines = useBothVaccines();
	const allDosesVaccines = useDosesVaccines();
	const allOnlyVaccines = useOnlyVaccines();
	const allGroupScreenings = useGroupScreenings();
	const allSingleScreenings = useSingleScreenings();
	const allToggleScreenings = useToggledScreenings();
	const functionals = useFunctionals();
	const conditions = useConditions();
	const allSingleDiagnostics = useSingleDiagnostics();
	const socialDrugHistory = useSocialDrugHistory();
	const socialDeterminants = useSocialDeterminants();

	const handleGenerationClick = async (_event: MouseEvent<HTMLButtonElement>) => {
		import(/* webpackChunkName: "generateCCPDoc" */ "./generateCCPDoc").then(async (module) => {
			await module.generateCCPDoc(
				"All",
				demographics,
				appointments,
				physicians,
				medications,
				medicationAllergies,
				physicalInformation,
				pneumococcalVaccine,
				allBothVaccines,
				allDosesVaccines,
				allOnlyVaccines,
				allGroupScreenings,
				allSingleScreenings,
				allToggleScreenings,
				functionals,
				conditions,
				allSingleDiagnostics,
				socialDrugHistory,
				socialDeterminants,
			);
		});
	};
	// useEffect(() => {
	// 	console.log("functionals: ", functionals.KATZADL!.activities.map(a => a.checked))
	// }, [functionals]);
	return (
		<Stack spacing={2} direction="row">
			<Button
				onClick={handleGenerationClick}
				variant="contained"
			>
				GENERATE DOCUMENT
			</Button>
		</Stack>
	);
};
