import { Draft } from "immer";
import { useImmerReducer } from "use-immer";
import { createContext, ReactNode, useContext, Dispatch } from "react";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import {
	SocialDeterminantsCodeWrapperType,
	SocialDeterminantsCodesWrapperType,
	SocialDeterminantsDraftType,
	SocialDeterminantsIndivQuestionType,
	SocialDeterminantsQuestionsType,
} from "src/types";
import { ZodSocialDeterminantsDraftType } from "src/types_zod";

import base from 'src/public/socialdeterminants/socialdeterminants.json';

parseZodReaderFriendly(ZodSocialDeterminantsDraftType, base);

const init = base as SocialDeterminantsDraftType;

export const SocialDeterminantsContext = createContext<SocialDeterminantsDraftType | null>(null);
export const SocialDeterminantsDispatchContext = createContext<Dispatch<SocialDeterminantsActionType> | null>(null);

export function useSocialDeterminants() {
	return useContext(SocialDeterminantsContext)!;
};

export function useSocialDeterminantsDispatch() {
	return useContext(SocialDeterminantsDispatchContext)!;
};

export interface SocialDeterminantsInitActionType {
	type: string;
	value: SocialDeterminantsDraftType;
};

export interface SocialDeterminantsCheckboxActionType {
	type: string;
	questionsGroupId: string;
	codeId: string;
	value: boolean;
	codeorcodes: string | undefined; // undefined due to IoFormControlLabel component prop typing
};

// export interface SocialDeterminantsNumActionType {
// 	type: string,
// 	id: string,
// 	value: number,
// };

// export interface SocialDeterminantsDateActionType {
// 	type: string;
// 	id: string,
// 	value: string | null;
// };

// export interface SocialDeterminantsAppointmentActionType {
// 	type: string;
// 	id: string,
// 	value: string | null;
// 	key: string;
// };

export type SocialDeterminantsActionType =
	| SocialDeterminantsInitActionType
	| SocialDeterminantsCheckboxActionType
// | SocialDeterminantsNumActionType
// | SocialDeterminantsDateActionType
// | SocialDeterminantsAppointmentActionType;

function socialDeterminantsReducer(draft: Draft<SocialDeterminantsDraftType>, action: SocialDeterminantsActionType) {
	switch (action.type) {
		case 'changeInitState': {
			const narrowedAction = action as SocialDeterminantsInitActionType;
			return narrowedAction.value;
		}
		case 'setCheckbox': {
			const narrowedAction = action as SocialDeterminantsCheckboxActionType;
			const socialDeterminantsItem = draft.filter(n => n.id === narrowedAction.questionsGroupId)[0];
			socialDeterminantsItem.questions = socialDeterminantsItem.questions.map(q => {
				if (narrowedAction.codeorcodes === undefined) {
					throw Error("why is codeorcodes undefined? shouldnt be")
				}

				if (narrowedAction.codeorcodes in q) {
					if (narrowedAction.codeorcodes === "code") {
						return {
							...(q as SocialDeterminantsCodeWrapperType),
							code: {
								...(q as SocialDeterminantsCodeWrapperType).code,
								checked: (q as SocialDeterminantsCodeWrapperType).code.id === narrowedAction.codeId ?
									narrowedAction.value :
									(q as SocialDeterminantsCodeWrapperType).code.checked,
							}
						};
					}
					else if (narrowedAction.codeorcodes === "codes") {
						return {
							...(q as SocialDeterminantsCodesWrapperType),
							codes: (q as SocialDeterminantsCodesWrapperType).codes.map(internalC => {
								return {
									...internalC,
									code: {
										...internalC.code,
										checked: internalC.code.id === narrowedAction.codeId ?
											narrowedAction.value :
											internalC.code.checked,
									}
								}
							})
						};
					}
				}
				return q
			});

			break;
		}
		// case 'setNumChange': {
		// 	const narrowedAction = action as SocialDeterminantsStrActionType;
		// 	const socialDeterminantsItem = draft.filter(n => n.id === narrowedAction.id)[0] as Exclude<SocialDeterminantsIndivType, SocialDeterminantsAppointmentType>;

		// 	socialDeterminantsItem.value = narrowedAction.value;
		// 	break;
		// }
		// case 'setDateChange': {
		// 	const narrowedAction = action as SocialDeterminantsDateActionType;
		// 	const socialDeterminantsItem = draft.filter(n => n.id === narrowedAction.id)[0] as Exclude<SocialDeterminantsIndivType, SocialDeterminantsAppointmentType>;

		// 	socialDeterminantsItem.value = narrowedAction.value;
		// 	break;
		// }
		// case 'setAppointmentChange': {
		// 	const narrowedAction = action as SocialDeterminantsAppointmentActionType;
		// 	const socialDeterminantsItem = draft.filter(n => n.id === narrowedAction.id)[0] as SocialDeterminantsAppointmentType;

		// 	socialDeterminantsItem[narrowedAction.key] = narrowedAction.value;
		// 	break;
		// }
		default: {
			return draft;
		}
	};
};

interface SocialDeterminantsPhysicianProps {
	children: ReactNode,
	testingInit?: SocialDeterminantsDraftType,
};

export function SocialDeterminantsProvider({
	children,
	testingInit,
}: SocialDeterminantsPhysicianProps) {
	const [socialDeterminants, dispatch] = useImmerReducer(socialDeterminantsReducer, testingInit ? testingInit : init as SocialDeterminantsDraftType);

	return (
		<SocialDeterminantsContext.Provider value={socialDeterminants}>
			<SocialDeterminantsDispatchContext.Provider value={dispatch}>
				{children}
			</SocialDeterminantsDispatchContext.Provider>
		</SocialDeterminantsContext.Provider>
	);
};
