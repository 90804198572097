import { ChangeEvent, useCallback } from 'react';
import {
	Collapse, 
	Stack,
} from '@mui/material';

import { useOnlyVaccinesDispatch } from 'src/components/physical/vaccines/OnlyVaccinesContext';
import { OnlyVaccineIndivType } from 'src/types';

import { IoDatePicker } from 'src/io/shells/IoDatePicker';
import { IoFormControlLabel } from 'src/io/wrappers/IoFormControlLabel';
import { ExternalIoFormControlLabel } from 'src/io/wrappers/ExternalIoFormControlLabel';

interface OnlyVaccineProps {
	vaccine: OnlyVaccineIndivType
};

export function OnlyVaccine({
	vaccine,
}: OnlyVaccineProps) {
	const dispatch = useOnlyVaccinesDispatch();

	const handleCheckbox = useCallback(
		(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
			dispatch({
				type: "setCheckbox",
				id: event.target.name,
				value: checked,
			});
		}, [dispatch]
	);

	const handleDateReceived = (
		value: string | null,
		id: string,
	) => {
		dispatch({
			type: "setDateReceived",
			id: id,
			value: value,
		});
	};

	return (
		<Stack
			direction="row"
			key={vaccine.id}
		>
			<ExternalIoFormControlLabel
				id={vaccine.id}
				checked={vaccine.checked}
				onExternalChange={handleCheckbox}
			/>
			<Collapse
				orientation="horizontal"
				in={vaccine.checked}
				unmountOnExit
			>
				<IoDatePicker
					id={vaccine.id}
					label="Date Received"
					onHandleChange={handleDateReceived}
				/>
			</Collapse>
		</Stack>
	);
};
