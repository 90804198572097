import dayjs, { Dayjs } from 'dayjs';
import {
	Grid,
} from '@mui/material';

import { useDemographics, useDemographicsDispatch } from './DemographicsContext';

import { ExternalIoDatePicker } from 'src/io/shells/ExternalIoDatePicker';
import { ExternalIoTextField } from 'src/io/shells/ExternalIoTextField';
import { DemographicsStrIndivType } from 'src/types';
import { usePneumococcalVaccineDispatch } from 'src/components/physical/vaccines/PneumococcalVaccine/PneumococcalVaccineContext';
import { useEffect, useMemo } from 'react';
import { parseZodReaderFriendly } from 'src/io/errorhandling/ioZod';
import { ZodDemographicsDraftType } from 'src/types_zod';

interface DemographicsProps {
	// mode: string,
};

// eslint-disable-next-line no-empty-pattern
export function Demographics({
	// mode,
}: DemographicsProps) {
	const demographics = useDemographics();

	const dispatchDemographics = useDemographicsDispatch();
	const dispatchPneumococcalVaccine = usePneumococcalVaccineDispatch();
	
	const ageInMonths = useMemo(() => {
		parseZodReaderFriendly(ZodDemographicsDraftType, demographics);
		const dobAsString = demographics.filter(cp => cp.id === "dateOfBirth")[0].value as string;
		const dobToAge = dayjs().diff(dayjs(dobAsString), "months");
		return dobToAge;
	}, [demographics]);

	const inValidAge = useMemo(() => {
		if (ageInMonths < 4) {
			return true;
		};

		return false;
	}, [ageInMonths]);

	const ageRangeKey = useMemo(() => {
		if (inValidAge) {
			return null;
		}
		else {
			if (228 <= ageInMonths && ageInMonths <= 768) { // 19-64
				return "nineteentosixtyfour";
			}
			else if (72 <= ageInMonths && ageInMonths <= 216) { // 6-18
				return "sixtoeighteen";
			}
			else if (24 <= ageInMonths && ageInMonths <= 59) {
				return "twentyfourmonthstofiftyninemonths";
			}
			else if (12 <= ageInMonths && ageInMonths <= 23) {
				return "twelvemonthstotwentythreemonths";
			}
			else if (7 <= ageInMonths && ageInMonths <= 11) {
				return "sevenmonthstoelevenmonths";
			}
			else if (4 <= ageInMonths && ageInMonths <= 6) {
				return "fourmonthstosixmonths";
			}

			return "sixtyfiveandup";
		};
	}, [inValidAge, ageInMonths]);

	useEffect(() => {
		dispatchPneumococcalVaccine({
			type: "setInValidAge",
			value: inValidAge,
		});
	}, [dispatchPneumococcalVaccine, inValidAge]);


	useEffect(() => {
		dispatchPneumococcalVaccine({
			type: "setAgeRangeKey",
			value: ageRangeKey,
		});
		dispatchPneumococcalVaccine({
			type: "changeInitState",
			value: ageRangeKey,
		});
	}, [dispatchPneumococcalVaccine, ageRangeKey]);
	const handleTextChange = (value: string, id: string) => {
		dispatchDemographics({
			type: "setTextChange",
			id: id,
			value: value,
		});
	};

	const handleDateChange = (
		value: {
			value: Dayjs | null;
			validationError: string | null;
		},
		id: string,
	) => {
		dispatchDemographics({
			type: "setDateChange",
			id: id,
			value: value.value === null ? null : value.value.format('MM/DD/YYYY'),
		});
	};

	return (
		<Grid
			id="demographics-toplevelgrid"
			sx={{
				alignContent: "flex-start"
			}}
			item
			container
		>
			<Grid
				id="demographics-toplevelgrid-item-container-level1-dates"
				item
				container
			>
				<Grid
					item
				>
					<ExternalIoDatePicker
						id="dateOfEstablishment"
						label="Date of Establishment"
						value={dayjs(demographics.filter(d => d.id === "dateOfEstablishment")[0].value)}
						setExternalState={(value: {
							value: Dayjs | null;
							validationError: string | null;
						}) => handleDateChange(value, "dateOfEstablishment")}
					/>
				</Grid>
				<Grid
					item
				>
					<ExternalIoDatePicker
						id="dateOfBirth"
						label="Date of Birth"
						value={dayjs(demographics.filter(d => d.id === "dateOfBirth")[0].value)}
						setExternalState={(value: {
							value: Dayjs | null;
							validationError: string | null;
						}) => handleDateChange(value, "dateOfBirth")}
					/>
				</Grid>
			</Grid>
			<Grid
				id="demographics-toplevelgrid-item-container-level1-accountnum"
				item
				container
			>
				<Grid
					item
				>
					<ExternalIoTextField
						id="accountNum"
						label="Account Num"
						value={(demographics.filter(d => d.id === "accountNum")[0] as DemographicsStrIndivType).value}
						setExternalState={handleTextChange}
					/>
				</Grid>
			</Grid>
		</Grid >
	);
};
