import { Dayjs } from 'dayjs';
import { MouseEvent, ChangeEvent, useState, useCallback, useMemo, useEffect } from 'react';
import {
	Button,
	Stack,
} from '@mui/material';

import { IoModalOnFormControlLabelCheckbox } from 'src/io/wrappers/IoModalOnFormControlLabelCheckbox';
import { usePneumococcalVaccineDispatch } from './PneumococcalVaccineContext';
import { AgeRangeType, DemographicsDateIndivType, PneumococcalVaccineDraftType, PneumococcalVaccineLocalCopyDraftType } from 'src/types';

import { PageConditions } from './PageConditions';
import { PageVacVersions } from './PageVacVersions';
import { ZodPneumococcalVaccineLocalCopyDraftType } from 'src/types_zod';
import { VACCINEVERSIONDOSE_INIT_OBJ } from 'src/io/functions/iofunctions';
import { createNewExternalState, resetLocalState } from './helpers';
import { parseZodReaderFriendly } from 'src/io/errorhandling/ioZod';
import { useDemographics } from 'src/components/administrative/demographics/DemographicsContext';

function usePage(
	pageNum: number,
	localVaccineCopy: PneumococcalVaccineLocalCopyDraftType,
	ageRange: string,
	handleAddVaccineVersion: (event: MouseEvent<HTMLButtonElement>) => void,
	handleRemoveVaccineVersion: (event: MouseEvent<HTMLButtonElement>, localVaccineCopyVersionId: number) => void,
	handleCheckboxRiskConditions: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
	handleToggleWhichVaccineVersion: (event: MouseEvent<HTMLElement, globalThis.MouseEvent>, value: string, localVaccineCopyVersionId: number) => void,
	handleDateReceived: (value: { value: Dayjs | null, validationError: string | null }, id: number) => void,
) {
	if (pageNum === 1) {
		return (
			<PageVacVersions // must be first page since risk conditions become a factor based on vaccine selection
				localVaccineCopyVersions={localVaccineCopy.versions}
				handleAddVaccineVersion={handleAddVaccineVersion}
				handleRemoveVaccineVersion={handleRemoveVaccineVersion}
				handleToggleWhichVaccineVersion={handleToggleWhichVaccineVersion}
				handleDateReceived={handleDateReceived}
			/>
		);
	}
	else { // pageNum === 2
		return (
			<PageConditions
				allRiskConditions={(localVaccineCopy[ageRange] as AgeRangeType).riskConditions}
				handleCheckboxRiskConditions={handleCheckboxRiskConditions}
			/>
		);
	};
};

interface PneumococcalVaccineProps {
	vaccine: PneumococcalVaccineDraftType;
	// age: number;
};

export function PneumococcalVaccine({
	vaccine,
	// age,
}: PneumococcalVaccineProps) {
	// changes should be set through submission ONLY
	const [localVaccineCopy, setLocalVaccineCopy] = useState<PneumococcalVaccineLocalCopyDraftType>(() => {
		return {
			id: vaccine.id,
			checked: vaccine.checked,
			versions: vaccine.versions,
			fourmonthstosixmonths: vaccine.fourmonthstosixmonths,
			sevenmonthstoelevenmonths: vaccine.sevenmonthstoelevenmonths,
			twelvemonthstotwentythreemonths: vaccine.twelvemonthstotwentythreemonths,
			twentyfourmonthstofiftyninemonths: vaccine.twentyfourmonthstofiftyninemonths,
			sixtoeighteen: vaccine.sixtoeighteen,
			nineteentosixtyfour: vaccine.nineteentosixtyfour,
			sixtyfiveandup: vaccine.sixtyfiveandup,
		};
	});

	const [pageNum, setPageNum] = useState<number>(1);
	const [open, setOpen] = useState<boolean>(false);
	const [submitted, setSubmitted] = useState<boolean>(false);
	const dispatchPneumococcalVaccine = usePneumococcalVaccineDispatch();

	const demographics = useDemographics();
	const demographicsDateOfBirth = useMemo(() => {
		return (demographics.filter(n => n.id === "dateOfBirth")[0] as DemographicsDateIndivType).value;
	}, [demographics]);

	const handleAddVaccineVersion = useCallback(
		(_event: MouseEvent<HTMLButtonElement>) => {
			parseZodReaderFriendly(ZodPneumococcalVaccineLocalCopyDraftType, localVaccineCopy);
			setLocalVaccineCopy(prev => {
				const tempPneumococcalVaccine = structuredClone(prev);
				tempPneumococcalVaccine.versions = [
					...prev.versions,
					VACCINEVERSIONDOSE_INIT_OBJ(localVaccineCopy.versions.length),
				];
				return tempPneumococcalVaccine;
			});
		},
		[localVaccineCopy]
	);

	const handleRemoveVaccineVersion = useCallback(
		(_event: MouseEvent<HTMLButtonElement>, localVaccineCopyVersionId: number) => {
			parseZodReaderFriendly(ZodPneumococcalVaccineLocalCopyDraftType, localVaccineCopy);
			setLocalVaccineCopy(prev => {
				const tempPneumococcalVaccine = structuredClone(prev);
				tempPneumococcalVaccine.versions = tempPneumococcalVaccine.versions.filter(version => {
					return version.id !== localVaccineCopyVersionId
				});
				tempPneumococcalVaccine.versions.forEach((version, index) => {
					version.id = index;
				});
				return tempPneumococcalVaccine;
			});
		},
		[localVaccineCopy]
	);

	const handleToggleWhichVaccineVersion = useCallback(
		(_event: MouseEvent<HTMLElement, globalThis.MouseEvent>, value: string, localVaccineCopyVersionId: number) => {
			parseZodReaderFriendly(ZodPneumococcalVaccineLocalCopyDraftType, localVaccineCopy);
			setLocalVaccineCopy(prev => {
				const tempPneumococcalVaccine = structuredClone(prev);
				tempPneumococcalVaccine.versions.forEach(version => {
					if (version.id === localVaccineCopyVersionId) {
						version.version = value;
					};
				});
				return tempPneumococcalVaccine;
			});
		},
		[localVaccineCopy],
	);

	const handleCheckboxRiskConditions = useCallback(
		(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
			parseZodReaderFriendly(ZodPneumococcalVaccineLocalCopyDraftType, localVaccineCopy);
			setLocalVaccineCopy(prev => {
				const tempPneumococcalVaccine = structuredClone(prev);
				(tempPneumococcalVaccine[vaccine.ageRange] as AgeRangeType).riskConditions = (tempPneumococcalVaccine[vaccine.ageRange] as AgeRangeType).riskConditions.map(p => {
					if (p.id === event.target.name) {
						return {
							...p,
							checked: checked,
						};
					}
					else {
						return p;
					};
				});

				return tempPneumococcalVaccine;
			});
		},
		[vaccine.ageRange, localVaccineCopy]
	);

	// currently, this event handler only updates vacversion datereceived
	// - i need to know which dose to update the dateReceived of, meaning i need a unique id, index good enough?
	const handleDateReceived = useCallback(
		(value: { value: Dayjs | null, validationError: string | null }, id: number) => {
			if (value.validationError === null) {
				parseZodReaderFriendly(ZodPneumococcalVaccineLocalCopyDraftType, localVaccineCopy);
				setLocalVaccineCopy(prev => {
					return {
						...prev,
						versions: prev.versions.map(p => {
							if (p.id === id) {
								return {
									...p,
									dateReceived: value.value === null ? null : value.value.format('MM/DD/YYYY'),
								};
							}
							else {
								return p;
							};
						}),
					}
				});
			};
		},
		[localVaccineCopy]
	);

	const page = usePage(
		pageNum,
		localVaccineCopy,
		vaccine.ageRange,
		handleAddVaccineVersion,
		handleRemoveVaccineVersion,
		handleCheckboxRiskConditions,
		handleToggleWhichVaccineVersion,
		handleDateReceived,
	);

	const handleCheckbox = useCallback(
		(_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
			parseZodReaderFriendly(ZodPneumococcalVaccineLocalCopyDraftType, localVaccineCopy);
			dispatchPneumococcalVaccine({
				type: "setCheckbox",
				value: checked,
			});
			setSubmitted(false);
			setLocalVaccineCopy(prev => resetLocalState(prev));
		},
		[dispatchPneumococcalVaccine, localVaccineCopy]
	);

	const handleClose = useCallback(
		(_event: object, reason: "backdropClick" | "escapeKeyDown" | "submit") => {
			// console.log(localVaccineCopy.versions)
			parseZodReaderFriendly(ZodPneumococcalVaccineLocalCopyDraftType, localVaccineCopy);
			if (reason === "submit") {
				const tempPneumococcalVaccine = structuredClone(localVaccineCopy);
				const temp = createNewExternalState(tempPneumococcalVaccine, demographicsDateOfBirth, vaccine.ageRange);

				dispatchPneumococcalVaccine({
					type: "setExternalStateToSubmittedState",
					value: temp as PneumococcalVaccineDraftType,
				});
				setSubmitted(true);
			}
			else {
				dispatchPneumococcalVaccine({
					type: "clearStateCauseDialogClosedNotSubmitted",
				});
				setLocalVaccineCopy(prev => resetLocalState(prev));
				setSubmitted(false);
			};

			setOpen(false);
		},
		[
			demographicsDateOfBirth,
			localVaccineCopy,
			vaccine.ageRange,
			dispatchPneumococcalVaccine,
		]
	);

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		if ((event.target as HTMLElement).textContent === "Back") {
			if (pageNum > 1) {
				setPageNum(prev => prev - 1);
			};
		}
		else if ((event.target as HTMLElement).textContent === "Next") {
			if (pageNum < 2) {
				setPageNum(prev => prev + 1);
			};
		}
		else if ((event.target as HTMLElement).textContent === "Submit") {
			handleClose(event, "submit");
		}
		else if ((event.target as HTMLElement).textContent === "pneumococcal") {
			setOpen(true);
		};
	};

	useEffect(() => {
		if (open) {
			setLocalVaccineCopy({
				id: vaccine.id,
				checked: vaccine.checked,
				versions: vaccine.versions,
				fourmonthstosixmonths: vaccine.fourmonthstosixmonths,
				sevenmonthstoelevenmonths: vaccine.sevenmonthstoelevenmonths,
				twelvemonthstotwentythreemonths: vaccine.twelvemonthstotwentythreemonths,
				twentyfourmonthstofiftyninemonths: vaccine.twentyfourmonthstofiftyninemonths,
				sixtoeighteen: vaccine.sixtoeighteen,
				nineteentosixtyfour: vaccine.nineteentosixtyfour,
				sixtyfiveandup: vaccine.sixtyfiveandup,
			})
		}
	}, [open, vaccine]);

	// useEffect(() => {
	// 	console.log(localVaccineCopy);
	// }, [localVaccineCopy]);

	return (
		<Stack
			direction="row"
		>
			<IoModalOnFormControlLabelCheckbox
				id={localVaccineCopy.id}
				checked={vaccine.checked} // external vaccine state must control top level checkbox
				submitted={submitted}
				invalidAge={vaccine.invalidAge}
				open={open}
				handleCheckbox={handleCheckbox}
				handleClick={handleClick}
				handleClose={(event: object, reason: "backdropClick" | "escapeKeyDown") =>
					handleClose(event, reason)}
			>
				<Stack
					sx={{
						// eslint-disable-next-line @typescript-eslint/prefer-as-const
						position: 'absolute' as 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						// width: 700,
						width: "90%",
						// height: 800,
						height: "90vh",
						bgcolor: 'gray',
						border: '2px solid #000',
						boxShadow: 24,
						justifyContent: 'space-between'
					}}
				>
					<Stack
						sx={{
							overflow: "auto"
						}}
					>
						{page}
					</Stack>
					<Stack>
						<Button
							onClick={handleClick}
							variant="contained"
						>
							Back
						</Button>
						<Button
							data-testid="nextButton"
							onClick={handleClick}
							variant="contained"
						>
							Next
						</Button>
						<Button
							data-testid="submitButton"
							onClick={handleClick}
							variant="contained"
						>
							Submit
						</Button>
					</Stack>
				</Stack>
			</IoModalOnFormControlLabelCheckbox>
		</Stack>
	);
};
