import { createContext, useContext, Dispatch, ReactNode, useEffect } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import {
	SocialDrugHistoryDraftType,
	SocialDrugHistoryIllegalIndivType,
	SocialDrugHistoryIllegalValueBooleanIndivType,
	SocialDrugHistoryLegalIndivType,
	SocialDrugHistoryLegalValueStringIndivType,
} from "src/types";
import { ZodSocialDrugHistoryDraftType } from "src/types_zod";

import base from 'src/public/conditionsanddiseases/socialdrughistory.json';

parseZodReaderFriendly(ZodSocialDrugHistoryDraftType, base);

const init = base as SocialDrugHistoryDraftType;

export const SocialDrugHistoryContext = createContext<SocialDrugHistoryDraftType | null>(null);
export const SocialDrugHistoryDispatchContext = createContext<Dispatch<SocialDrugHistoryActionType> | null>(null);

export function useSocialDrugHistory() {
	return useContext(SocialDrugHistoryContext)!;
};

export function useSocialDrugHistoryDispatch() {
	return useContext(SocialDrugHistoryDispatchContext)!;
};

export interface SocialDrugHistoryInitActionType {
	type: string;
	value: SocialDrugHistoryDraftType;
};

export interface SocialDrugHistoryToggleButtonGroupActionType {
	type: string;
	id: string;
	value: string;
};

export interface SocialDrugHistoryStrActionType {
	type: string;
	parentId: string;
	id: string;
	value: string;
};

export interface SocialDrugHistoryCheckboxActionType {
	type: string;
	parentId: string;
	id: string;
	value: boolean;
};

export type SocialDrugHistoryActionType =
	SocialDrugHistoryInitActionType
	| SocialDrugHistoryToggleButtonGroupActionType
	| SocialDrugHistoryStrActionType
	| SocialDrugHistoryCheckboxActionType;

function socialDrugHistoryReducer(draft: Draft<SocialDrugHistoryDraftType>, action: SocialDrugHistoryActionType) {
	switch (action.type) {
		case 'initialize': {
			const narrowedAction = action as SocialDrugHistoryInitActionType;
			return narrowedAction.value;
		}
		case 'setToggleButtonGroup': {
			const narrowedAction = action as SocialDrugHistoryToggleButtonGroupActionType;

			const socialdrughistorygroup = draft.filter(n => n.id === narrowedAction.id)[0];

			// enforcing that an option MUST be selected, cannot deselect option so togglebuttongroup is "blank"
			if (narrowedAction.value !== null) {
				socialdrughistorygroup.toggleButtonValue = narrowedAction.value;
			};

			break;
		}
		case 'setNum': {
			const narrowedAction = action as SocialDrugHistoryStrActionType;

			// explicit type conversion dependent on either "timesperday" or "timesperyear" being id in narrowedAction
			const socialdrughistorylegalgroup = (draft.filter(n => n.id === narrowedAction.parentId)[0] as SocialDrugHistoryLegalIndivType);

			// spread objs must be object types and implicit conversion not working so explicit required
			socialdrughistorylegalgroup[narrowedAction.id] = {
				...(socialdrughistorylegalgroup[narrowedAction.id] as SocialDrugHistoryLegalValueStringIndivType),
				value: narrowedAction.value,
			};

			break;
		}
		case 'setCheckbox': {
			const narrowedAction = action as SocialDrugHistoryCheckboxActionType;

			// explicit type conversion dependent on either "attemptedrehab" or "hospitalized" being id in narrowedAction
			const socialdrughistoryillegalgroup = (draft.filter(n => n.id === narrowedAction.parentId)[0] as SocialDrugHistoryIllegalIndivType);

			// spread objs must be object types and implicit conversion not working so explicit required
			socialdrughistoryillegalgroup[narrowedAction.id] = {
				...(socialdrughistoryillegalgroup[narrowedAction.id] as SocialDrugHistoryIllegalValueBooleanIndivType),
				value: narrowedAction.value,
			};
			break;
		}
		default: {
			return draft;
		}
	};
};

interface SocialDrugHistoryPhysicianProps {
	children: ReactNode,
	testingInit?: SocialDrugHistoryDraftType,
};

export function SocialDrugHistoryProvider({
	children,
	testingInit,
}: SocialDrugHistoryPhysicianProps) {
	const [socialDrugHistory, dispatch] = useImmerReducer(socialDrugHistoryReducer, testingInit ? testingInit : init as SocialDrugHistoryDraftType);

	// useEffect(() => {
	// 	let ignore = false;
	// 	import(
	// 		/* webpackChunkName: "singlediagnostics" */
	// 		`../../public/conditionsanddiseases/diagnostics/singlediagnostics.json`
	// 	)
	// 		.then(({ default: myJson }: { default: SocialDrugHistoryDraftType }) => {
	// 			if (!ignore) {
	// 				dispatch({
	// 					type: "initialize",
	// 					value: myJson
	// 				});
	// 			};
	// 		})
	// 		.catch(err => console.log("err: ", err));

	// 	return () => {
	// 		ignore = true;
	// 	};
	// }, [dispatch]);

	return (
		<SocialDrugHistoryContext.Provider value={socialDrugHistory}>
			<SocialDrugHistoryDispatchContext.Provider value={dispatch}>
				{children}
			</SocialDrugHistoryDispatchContext.Provider>
		</SocialDrugHistoryContext.Provider>
	);
};
