import { createContext, Dispatch, ReactNode, useContext, useEffect } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import { CheckedConditionsDraftType } from "src/types";
import { ZodCheckedConditionsDraftType } from "src/types_zod";

import base from 'src/public/conditionsanddiseases/conditions.json';

parseZodReaderFriendly(ZodCheckedConditionsDraftType, base);

const init = base as CheckedConditionsDraftType;

export const ConditionsContext = createContext<CheckedConditionsDraftType | null>(null);
export const ConditionsDispatchContext = createContext<Dispatch<ConditionsActionType> | null>(null);

export function useConditions() {
	return useContext(ConditionsContext)!;
};

export function useConditionsDispatch() {
	return useContext(ConditionsDispatchContext)!;
};

export interface ConditionsInitActionType {
	type: string;
	value: CheckedConditionsDraftType;
};

export interface ConditionsCheckboxActionType {
	type: string;
	id: string;
	value: boolean;
};

export interface ConditionsSelectionActionType {
	type: string;
	id: string;
	value: string;
};

export interface ConditionsSetHBA1CValueActionType {
	type: string;
	id: string;
	value: number;
};

export interface ConditionsSetHBA1CDateActionType {
	type: string;
	id: string;
	value: string | null;
};

export interface ConditionsRemoveTreatmentGoalActionType {
	type: string;
	id: string;
	treatmentGoal: string;
};

export interface ConditionsRemovePlannedInterventionctionType {
	type: string;
	id: string;
	plannedIntervention: string;
};

export type ConditionsActionType =
	ConditionsInitActionType
	| ConditionsCheckboxActionType
	| ConditionsSelectionActionType
	| ConditionsSetHBA1CValueActionType
	| ConditionsSetHBA1CDateActionType
	| ConditionsRemoveTreatmentGoalActionType
	| ConditionsRemovePlannedInterventionctionType;

function conditionsReducer(draft: Draft<CheckedConditionsDraftType>, action: ConditionsActionType) {
	switch (action.type) {
		case 'initialize': {
			const narrowedAction = action as ConditionsInitActionType;
			return narrowedAction.value;
		}
		case 'setCheckbox': {
			const narrowedAction = action as ConditionsCheckboxActionType;

			const condition = draft.filter(n => n.label === narrowedAction.id)[0];
			condition.checked = narrowedAction.value;

			break;
		}
		case 'setConditionSelection': {
			const narrowedAction = action as ConditionsSelectionActionType;

			const condition = draft.filter(n => n.id === narrowedAction.id)[0];
			condition.selection = narrowedAction.value;

			break;
		}
		case 'setHBA1CValue': {
			const narrowedAction = action as ConditionsSetHBA1CValueActionType;

			const hba1cCondition = draft.filter(n => n.id === "type2diabetesmellitus")[0];
			hba1cCondition.hba1c = hba1cCondition.hba1c!.map(h => {
				return {
					...h,
					value: h.id === narrowedAction.id ? narrowedAction.value : h.value,
				};
			});
			// condition.hba1c!.value = narrowedAction.value;

			break;
		}
		case 'setHBA1CDate': {
			const narrowedAction = action as ConditionsSetHBA1CDateActionType;

			const hba1cCondition = draft.filter(n => n.id === "type2diabetesmellitus")[0];
			hba1cCondition.hba1c = hba1cCondition.hba1c!.map(h => {
				return {
					...h,
					date: h.id === narrowedAction.id ? narrowedAction.value : h.date,
				};
			});
			// condition.hba1c!.date = narrowedAction.value;

			break;
		}
		case "removeTreatmentGoalinPhysicianType": {
			const narrowedAction = action as ConditionsRemoveTreatmentGoalActionType;
			const conditionType = draft.filter(n => n.id === narrowedAction.id)[0];
			conditionType.treatmentGoals = conditionType.treatmentGoals.filter(treatmentGoal => treatmentGoal !== narrowedAction.treatmentGoal);

			break;
		}
		case "removePlannedInterventioninPhysicianType": {
			const narrowedAction = action as ConditionsRemovePlannedInterventionctionType;
			const conditionType = draft.filter(n => n.id === narrowedAction.id)[0];
			conditionType.plannedInterventions = conditionType.plannedInterventions.filter(plannedIntervention => plannedIntervention !== narrowedAction.plannedIntervention);

			break;
		}
		default: {
			return draft;
		}
	};
};

interface ConditionsPhysicianProps {
	children: ReactNode
	testingInit?: CheckedConditionsDraftType,
};

export function ConditionsProvider({
	children,
	testingInit,
}: ConditionsPhysicianProps) {
	const [conditions, dispatch] = useImmerReducer(conditionsReducer, testingInit ? testingInit : init as CheckedConditionsDraftType);

	// useEffect(() => {
	// 	let ignore = false;
	// 	import(
	// 		/* webpackChunkName: "conditions" */
	// 		`../../../public/conditionsanddiseases/conditions.json`
	// 	)
	// 		.then(({ default: myJson }: { default: CheckedConditionsDraftType }) => {
	// 			if (!ignore) {
	// 				dispatch({
	// 					type: "initialize",
	// 					value: myJson
	// 				});
	// 			};
	// 		})
	// 		.catch(err => console.log("err: ", err));

	// 	return () => {
	// 		ignore = true;
	// 	};
	// }, [dispatch]);

	return (
		<ConditionsContext.Provider value={conditions}>
			<ConditionsDispatchContext.Provider value={dispatch}>
				{children}
			</ConditionsDispatchContext.Provider>
		</ConditionsContext.Provider>
	);
};
