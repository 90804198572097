import { createContext, ReactNode, useContext, Dispatch, useEffect } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import { PhysiciansDraftType, PhysicianType } from "src/types";
import { ZodPhysiciansDraftType } from "src/types_zod";

import base from 'src/public/administrative/physicians.json';

parseZodReaderFriendly(ZodPhysiciansDraftType, base);

const init = base as PhysiciansDraftType;

export const PhysiciansContext = createContext<PhysiciansDraftType | null>(null);
export const PhysiciansDispatchContext = createContext<Dispatch<PhysiciansActionType> | null>(null);

export function usePhysicians() {
	return useContext(PhysiciansContext)!;
};

export function usePhysiciansDispatch() {
	return useContext(PhysiciansDispatchContext)!;
};

export interface PhysiciansInitActionType {
	type: string;
	value: PhysiciansDraftType;
};

export interface PhysiciansCheckboxActionType {
	type: string,
	id: string,
	value: boolean,
};

export interface PhysiciansSelectionActionType {
	type: string;
	id: string;
	value: PhysicianType;
	index: number;
};

export interface PhysiciansAddPhysicianActionType {
	type: string;
	id: string;
	physician: string;
	label: string;
	phone: string;
};

export interface PhysiciansRemovePhysicianActionType {
	type: string;
	id: string;
	physician: string;
};

export type PhysiciansActionType =
	PhysiciansInitActionType
	| PhysiciansCheckboxActionType
	| PhysiciansSelectionActionType
	| PhysiciansAddPhysicianActionType
	| PhysiciansRemovePhysicianActionType;

function physiciansReducer(draft: Draft<PhysiciansDraftType>, action: PhysiciansActionType) {
	switch (action.type) {
		case 'initialize': {
			const narrowedAction = action as PhysiciansInitActionType;
			return narrowedAction.value;
		}
		case 'setCheckbox': {
			const narrowedAction = action as PhysiciansCheckboxActionType;
			const physicianType = draft.filter(n => n.id === narrowedAction.id)[0];
			physicianType.checked = narrowedAction.value;
			if (!physicianType.checked) {
				physicianType.selections = [
					null,
					null,
				];
			};
			break;
		}
		case 'setPhysicianSelection': {
			const narrowedAction = action as PhysiciansSelectionActionType;
			const physicianType = draft.filter(n => n.id === narrowedAction.id)[0];

			// find the physician object associated with the selected physician name and set selection property to it
			const res = physicianType.physicians.find(p => p.label === narrowedAction.value?.label);
			if (res === undefined) {
				physicianType.selections[narrowedAction.index] = null;
			}
			else {
				physicianType.selections[narrowedAction.index] = res;
			}

			break;
		}
		case 'addPhysicianinPhysicianType': {
			const narrowedAction = action as PhysiciansAddPhysicianActionType;
			const physicianType = draft.filter(n => n.id === narrowedAction.id)[0];
			physicianType.physicians.push({
				physician: narrowedAction.physician,
				label: narrowedAction.label,
				phone: narrowedAction.phone,
			});

			break;
		}
		case 'removePhysicianinPhysicianType': {
			const narrowedAction = action as PhysiciansRemovePhysicianActionType;
			const physicianType = draft.filter(n => n.id === narrowedAction.id)[0];
			physicianType.physicians = physicianType.physicians.filter(prov => prov.label !== narrowedAction.physician);

			break;
		}
		default: {
			return draft;
		}
	};
};

interface PhysiciansPhysicianProps {
	children: ReactNode,
	testingInit?: PhysiciansDraftType,
};

export function PhysiciansProvider({
	children,
	testingInit,
}: PhysiciansPhysicianProps) {
	const [physicians, dispatch] = useImmerReducer(physiciansReducer, testingInit ? testingInit : init as PhysiciansDraftType);
	
	// useEffect(() => {
	// 	let ignore = false;
	// 	import(
	// 		/* webpackChunkName: "physicians" */
	// 		`../../../public/administrative/physicians.json`
	// 	)
	// 		.then(({ default: myJson }: { default: PhysiciansDraftType }) => {
	// 			if (!ignore) {
	// 				dispatch({
	// 					type: "initialize",
	// 					value: myJson
	// 				});
	// 			};
	// 		})
	// 		.catch(err => console.log("err: ", err));

	// 	return () => {
	// 		ignore = true;
	// 	};
	// }, [dispatch]);

	return (
		<PhysiciansContext.Provider value={physicians}>
			<PhysiciansDispatchContext.Provider value={dispatch}>
				{children}
			</PhysiciansDispatchContext.Provider>
		</PhysiciansContext.Provider>
	);
};
