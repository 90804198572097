import { createContext, useContext, Dispatch, ReactNode, useEffect } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import { SingleDiagnosticDraftType } from "src/types";
import { ZodSingleDiagnosticDraftType } from "src/types_zod";

import base from 'src/public/conditionsanddiseases/singlediagnostics.json';

parseZodReaderFriendly(ZodSingleDiagnosticDraftType, base);

const init = base as SingleDiagnosticDraftType;

export const SingleDiagnosticsContext = createContext<SingleDiagnosticDraftType | null>(null);
export const SingleDiagnosticsDispatchContext = createContext<Dispatch<SingleDiagnosticActionType> | null>(null);

export function useSingleDiagnostics() {
	return useContext(SingleDiagnosticsContext)!;
};

export function useSingleDiagnosticsDispatch() {
	return useContext(SingleDiagnosticsDispatchContext)!;
};

export interface SingleDiagnosticInitActionType {
	type: string;
	value: SingleDiagnosticDraftType;
};

export interface SingleDiagnosticCheckboxActionType {
	type: string;
	id: string;
	value: boolean;
};

export interface SingleDiagnosticLinkCheckedActionType {
	type: string;
	id: string;
	value: boolean;
	parent: string;
};

export interface SingleDiagnosticDateReceivedActionType {
	type: string;
	id: string;
	value: string | null;
	parent: string;
};

export interface SingleDiagnosticsToggleDateDueModifierActionType {
	type: string;
	id: string;
	value: string;
};

export interface SingleDiagnosticDateDueActionType {
	type: string;
	id: string;
	value: string | null;
	parent: string;
};

export type SingleDiagnosticActionType =
	SingleDiagnosticInitActionType
	| SingleDiagnosticCheckboxActionType
	| SingleDiagnosticLinkCheckedActionType
	| SingleDiagnosticDateReceivedActionType
	| SingleDiagnosticsToggleDateDueModifierActionType
	| SingleDiagnosticDateDueActionType;

function singleDiagnosticsReducer(draft: Draft<SingleDiagnosticDraftType>, action: SingleDiagnosticActionType) {
	switch (action.type) {
		case 'initialize': {
			const narrowedAction = action as SingleDiagnosticInitActionType;
			return narrowedAction.value;
		}
		case 'setCheckbox': {
			const narrowedAction = action as SingleDiagnosticCheckboxActionType;

			const diagnostic = draft.filter(n => n.id === narrowedAction.id)[0];
			diagnostic.checked = narrowedAction.value;
			diagnostic.toggleButtonValue = narrowedAction.value ? diagnostic.toggleOptions[0].id : null;
			diagnostic.dateReceived = null;
			diagnostic.dateDue = null;
			break;
		}
		case 'setLinkChecked': {
			const narrowedAction = action as SingleDiagnosticLinkCheckedActionType;

			const diagnostic = draft.filter(n => n.id === narrowedAction.id)[0];
			diagnostic.linkChecked = narrowedAction.value;
			// diagnostic.toggleButtonValue = narrowedAction.value ? diagnostic.toggleOptions[0].id : null;
			// diagnostic.dateReceived = null;
			// diagnostic.dateDue = null;
			break;
		}
		case 'setDateReceived': {
			const narrowedAction = action as SingleDiagnosticDateReceivedActionType;

			const diagnostic = draft.filter(n => n.id === narrowedAction.id)[0];
			diagnostic.dateReceived = narrowedAction.value;
			break;
		}
		case 'toggleDateDueModifier': {
			const narrowedAction = action as SingleDiagnosticsToggleDateDueModifierActionType;

			const diagnostic = draft.filter(n => n.id === narrowedAction.id)[0];
			diagnostic.toggleButtonValue = narrowedAction.value;
			// if (diagnostic.toggleButtonValue === null && diagnostic.dateReceived !== null) {
			// 	const moddedVal = modifyDateAccordingToTest(narrowedAction.id, dayjs(diagnostic.dateReceived), "diagnosis");
			// 	const val = moddedVal.format('MM/DD/YYYY')
			// 	diagnostic.dateDue = val;
			// }
			// else {
			// 	diagnostic.dateDue = null;
			// };

			break;
		}
		case 'setDateDue': {
			const narrowedAction = action as SingleDiagnosticDateDueActionType;

			const diagnostic = draft.filter(n => n.id === narrowedAction.id)[0];
			diagnostic.dateDue = narrowedAction.value;

			break;
		}
		default: {
			return draft;
		}
	};
};

interface SingleDiagnosticsPhysicianProps {
	children: ReactNode,
	testingInit?: SingleDiagnosticDraftType,
};

export function SingleDiagnosticsProvider({
	children,
	testingInit,
}: SingleDiagnosticsPhysicianProps) {
	const [singleDiagnostics, dispatch] = useImmerReducer(singleDiagnosticsReducer, testingInit ? testingInit : init as SingleDiagnosticDraftType);

	// useEffect(() => {
	// 	let ignore = false;
	// 	import(
	// 		/* webpackChunkName: "singlediagnostics" */
	// 		`../../public/conditionsanddiseases/diagnostics/singlediagnostics.json`
	// 	)
	// 		.then(({ default: myJson }: { default: SingleDiagnosticDraftType }) => {
	// 			if (!ignore) {
	// 				dispatch({
	// 					type: "initialize",
	// 					value: myJson
	// 				});
	// 			};
	// 		})
	// 		.catch(err => console.log("err: ", err));

	// 	return () => {
	// 		ignore = true;
	// 	};
	// }, [dispatch]);

	return (
		<SingleDiagnosticsContext.Provider value={singleDiagnostics}>
			<SingleDiagnosticsDispatchContext.Provider value={dispatch}>
				{children}
			</SingleDiagnosticsDispatchContext.Provider>
		</SingleDiagnosticsContext.Provider>
	);
};
