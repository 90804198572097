import { ChangeEvent, Fragment, useCallback } from 'react';
import {
	Box,
	FormLabel,
	FormControl,
	FormGroup,
} from '@mui/material';

import { useMedicationAllergies, useMedicationAllergiesDispatch } from 'src/components/medications/MedicationAllergiesContext';
import { IoFormControlLabel } from 'src/io/wrappers/IoFormControlLabel';
import { ExternalIoFormControlLabel } from 'src/io/wrappers/ExternalIoFormControlLabel';

export function MedicationAllergies(
) {
	const allMedications = useMedicationAllergies();
	const dispatch = useMedicationAllergiesDispatch();

	const handleCheckbox = useCallback(
		(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
			dispatch({
				type: "setCheckbox",
				id: event.target.name,
				value: checked,
			});
		}, [dispatch]
	);

	return (
		<
			// Box
			// sx={{ display: 'flex' }}
			>
			<FormControl
				sx={{
					// marginLeft: 9,
					// paddingBottom: 20,
					// height: "50%",
					overflow: "auto",
					// height: "400px",
					width: "100%"
				}}
				component="fieldset"
				variant="standard"
			>
				<FormLabel
					component="legend"
				>
					Choose medication allergies:
				</FormLabel>
				<FormGroup>
					{allMedications.map(medication => {
						return (
							<Fragment
								key={medication.id}
							>
								<ExternalIoFormControlLabel
									id={medication.value} // NOT label, as that comes from breaking down init json
									checked={medication.checked}
									onExternalChange={handleCheckbox}
								/>
							</Fragment>
						);
					})}
				</FormGroup>
			</FormControl>
		</
		// Box
		>
	);
};
