import dayjs from "dayjs";
import { createContext, useContext, Dispatch, ReactNode, useEffect } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import { SingleScreeningDraftType } from "src/types";
import { ZodSingleScreeningDraftType } from "src/types_zod";

import base from 'src/public/screenings/singlescreenings.json';
import { modifyDateAccordingToTest } from "src/io/wrappers/IoLinkedDates";

parseZodReaderFriendly(ZodSingleScreeningDraftType, base);

const init = base as SingleScreeningDraftType;

export const SingleScreeningsContext = createContext<SingleScreeningDraftType | null>(null);
export const SingleScreeningsDispatchContext = createContext<Dispatch<SingleScreeningActionType> | null>(null);

export function useSingleScreenings() {
	return useContext(SingleScreeningsContext)!;
};

export function useSingleScreeningsDispatch() {
	return useContext(SingleScreeningsDispatchContext)!;
};

export interface SingleScreeningInitActionType {
	type: string;
	value: SingleScreeningDraftType;
};

export interface SingleScreeningCheckboxActionType {
	type: string;
	id: string;
	value: boolean;
};

export interface SingleScreeningLinkCheckedActionType {
	type: string;
	id: string;
	value: boolean;
	parent: string;
};

export interface SingleScreeningDateReceivedActionType {
	type: string;
	id: string;
	value: string | null;
	parent: string;
};

export interface SingleScreeningToggleDateDueModifierActionType {
	type: string;
	id: string;
	value: string;
};

export interface SingleScreeningDateDueActionType {
	type: string;
	id: string;
	value: string | null;
	parent: string;
};

export type SingleScreeningActionType =
	SingleScreeningInitActionType
	| SingleScreeningCheckboxActionType
	| SingleScreeningLinkCheckedActionType
	| SingleScreeningDateReceivedActionType
	| SingleScreeningToggleDateDueModifierActionType
	| SingleScreeningDateDueActionType;

function singleScreeningsReducer(draft: Draft<SingleScreeningDraftType>, action: SingleScreeningActionType) {
	switch (action.type) {
		case 'initialize': {
			const narrowedAction = action as SingleScreeningInitActionType;
			return narrowedAction.value;
		}
		case 'setCheckbox': {
			const narrowedAction = action as SingleScreeningCheckboxActionType;

			const screening = draft.filter(n => n.id === narrowedAction.id)[0];
			screening.checked = narrowedAction.value;
			screening.toggleButtonValue = narrowedAction.value ? screening.toggleOptions[0].id : null;
			screening.dateReceived = null;
			screening.dateDue = null;
			break;
		}
		case 'setLinkChecked': {
			const narrowedAction = action as SingleScreeningLinkCheckedActionType;

			const screening = draft.filter(n => n.id === narrowedAction.id)[0];
			screening.linkChecked = narrowedAction.value;
			// screening.toggleButtonValue = narrowedAction.value ? screening.toggleOptions[0].id : null;
			// screening.dateReceived = null;
			// screening.dateDue = null;
			break;
		}
		case 'setDateReceived': {
			const narrowedAction = action as SingleScreeningDateReceivedActionType;

			const screening = draft.filter(n => n.id === narrowedAction.id)[0];
			screening.dateReceived = narrowedAction.value;
			break;
		}
		case 'toggleDateDueModifier': {
			const narrowedAction = action as SingleScreeningToggleDateDueModifierActionType;

			const screening = draft.filter(n => n.id === narrowedAction.id)[0];
			screening.toggleButtonValue = narrowedAction.value;
			if (screening.toggleButtonValue === null && screening.dateReceived !== null) {
				const moddedVal = modifyDateAccordingToTest(narrowedAction.id, dayjs(screening.dateReceived), "screening");
				const val = moddedVal.format('MM/DD/YYYY')
				screening.dateDue = val;
			}
			else {
				screening.dateDue = null;
			};
			break;
		}
		case 'setDateDue': {
			const narrowedAction = action as SingleScreeningDateDueActionType;

			const screening = draft.filter(n => n.id === narrowedAction.id)[0];
			screening.dateDue = narrowedAction.value;

			break;
		}
		default: {
			return draft;
		}
	};
};

interface SingleScreeningsPhysicianProps {
	children: ReactNode;
	testingInit?: SingleScreeningDraftType;
};

export function SingleScreeningsProvider({
	children,
	testingInit,
}: SingleScreeningsPhysicianProps) {
	const [singleScreenings, dispatch] = useImmerReducer(singleScreeningsReducer, testingInit ? testingInit : init as SingleScreeningDraftType);

	// useEffect(() => {
	// 	let ignore = false;
	// 	import(
	// 		/* webpackChunkName: "singlescreenings" */
	// 		`../../public/screenings/singlescreenings.json`
	// 	)
	// 		.then(({ default: myJson }: { default: SingleScreeningDraftType }) => {
	// 			if (!ignore) {
	// 				dispatch({
	// 					type: "initialize",
	// 					value: myJson
	// 				});
	// 			};
	// 		})
	// 		.catch(err => console.log("err: ", err));

	// 	return () => {
	// 		ignore = true;
	// 	};
	// }, [dispatch]);

	return (
		<SingleScreeningsContext.Provider value={singleScreenings}>
			<SingleScreeningsDispatchContext.Provider value={dispatch}>
				{children}
			</SingleScreeningsDispatchContext.Provider>
		</SingleScreeningsContext.Provider>
	);
};
