import { MouseEvent, ChangeEvent, useCallback } from 'react';
import {
	Collapse,
	Stack,
} from '@mui/material';

import { useSingleScreeningsDispatch } from 'src/components/screenings/SingleScreeningsContext';
import { SingleScreeningIndivType } from 'src/types';

import { IoFormControlLabel } from 'src/io/wrappers/IoFormControlLabel';
import { IoLinkedDates } from 'src/io/wrappers/IoLinkedDates';
import { IoToggleButtonGroup } from 'src/io/wrappers/IoToggleButtonGroup';
import dayjs from 'dayjs';
import { ExternalIoFormControlLabel } from 'src/io/wrappers/ExternalIoFormControlLabel';

interface SingleScreeningProps {
	screening: SingleScreeningIndivType;
};

export function SingleScreening({
	screening,
}: SingleScreeningProps) {
	const dispatch = useSingleScreeningsDispatch();

	const handleCheckbox = useCallback(
		(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
			dispatch({
				type: "setCheckbox",
				id: event.target.name,
				value: checked,
			});
		}, [dispatch]
	);

	const handleToggleButtonChange = (
		_event: MouseEvent<HTMLElement, globalThis.MouseEvent>,
		value: string,
		screeningId: string,
	) => {
		dispatch({
			type: "toggleDateDueModifier",
			id: screeningId,
			value: value,
		});
	};

	const handleDateReceived = useCallback((
		value: string | null,
		id: string,
		parent: string,
	) => {
		dispatch({
			type: "setDateReceived",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);

	const handleDateDue = useCallback((
		value: string | null,
		id: string,
		parent: string,
	) => {
		dispatch({
			type: "setDateDue",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);

	const handleLinkChecked = useCallback((
		value: boolean,
		id: string,
		parent: string,

	) => {
		dispatch({
			type: "setLinkChecked",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);
	
	return (
		<Stack
			direction="row"
			key={screening.id}
		>
			<ExternalIoFormControlLabel
				id={screening.id}
				checked={screening.checked}
				onExternalChange={handleCheckbox}
			/>
			<Collapse
				orientation="horizontal"
				in={screening.checked}
				unmountOnExit
			>
				<IoLinkedDates
					screening={screening}
					externalDateReceived={dayjs(screening.dateReceived)}
					externalDateDue={dayjs(screening.dateDue)}
					externallinkChecked={screening.linkChecked}
					handleDateReceived={handleDateReceived}
					handleDateDue={handleDateDue}
					handleLinkChecked={handleLinkChecked}
					slot2={
						<Collapse
							sx={{ marginLeft: 2 }}
							orientation="horizontal"
							in={screening.checked}
							unmountOnExit
						>
							<IoToggleButtonGroup
								screeningOrDiagnostic={screening}
								onHandleChange={handleToggleButtonChange}
							/>
							{/* <FormControl>
								<ToggleButtonGroup
									size="small"
									value={screening.toggleButtonValue}
									exclusive
									onChange={(event, value) =>
										handleToggleButtonChange(event, value, screening.id)}
								>
									{screening.toggleOptions
										.map(tOption => {
											return (
												<ToggleButton
													key={tOption.id}
													value={tOption.id}
												>
													{tOption.id}
												</ToggleButton>
											);
										})
									}
								</ToggleButtonGroup>
							</FormControl> */}
						</Collapse>
					}
				/>
			</Collapse>
		</Stack>
	);
};
