import { createContext, ReactNode, useContext, Dispatch } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import { AppointmentDraftType, AppointmentIndivType } from "src/types";
import { ZodAppointmentDraftType } from "src/types_zod";

import base from 'src/public/administrative/appointments.json';

parseZodReaderFriendly(ZodAppointmentDraftType, base);

const init = base as AppointmentDraftType;

export const AppointmentsContext = createContext<AppointmentDraftType | null>(null);
export const AppointmentsDispatchContext = createContext<Dispatch<AppointmentsActionType> | null>(null);

export function useAppointments() {
	return useContext(AppointmentsContext)!;
};

export function useAppointmentsDispatch() {
	return useContext(AppointmentsDispatchContext)!;
};

export interface AppointmentsInitActionType {
	type: string;
	value: AppointmentDraftType;
};

export interface AppointmentsStrActionType {
	type: string,
	id: string,
	value: string,
};

export interface AppointmentsNumActionType {
	type: string,
	id: string,
	value: number,
};

export interface AppointmentsDateActionType {
	type: string;
	id: string,
	value: string | null;
	key: string;
};

export interface AppointmentsTimeActionType {
	type: string;
	id: string,
	value: string | null;
	key: string;
};

export type AppointmentsActionType =
	| AppointmentsInitActionType
	| AppointmentsStrActionType
	| AppointmentsNumActionType
	| AppointmentsDateActionType
	| AppointmentsTimeActionType;

function appointmentsReducer(draft: Draft<AppointmentDraftType>, action: AppointmentsActionType) {
	switch (action.type) {
		case 'changeInitState': {
			const narrowedAction = action as AppointmentsInitActionType;
			return narrowedAction.value;
		}
		case 'setAppointmentDateChange': {
			const narrowedAction = action as AppointmentsDateActionType;
			const appointmentsItem = draft.filter(n => n.id === narrowedAction.id)[0] as AppointmentIndivType;

			appointmentsItem[narrowedAction.key] = narrowedAction.value;
			break;
		}
		case 'setAppointmentTimeChange': {
			const narrowedAction = action as AppointmentsTimeActionType;
			const appointmentsItem = draft.filter(n => n.id === narrowedAction.id)[0] as AppointmentIndivType;

			appointmentsItem[narrowedAction.key] = narrowedAction.value;
			break;
		}
		default: {
			return draft;
		}
	};
};

interface AppointmentsPhysicianProps {
	children: ReactNode,
	testingInit?: AppointmentDraftType,
};

export function AppointmentsProvider({
	children,
	testingInit,
}: AppointmentsPhysicianProps) {
	const [appointments, dispatch] = useImmerReducer(appointmentsReducer, testingInit ? testingInit : init as AppointmentDraftType);

	return (
		<AppointmentsContext.Provider value={appointments}>
			<AppointmentsDispatchContext.Provider value={dispatch}>
				{children}
			</AppointmentsDispatchContext.Provider>
		</AppointmentsContext.Provider>
	);
};
