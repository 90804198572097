import dayjs, { Dayjs } from "dayjs";
import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
	Stack,
	Checkbox,
	Collapse,
} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { ExternalIoDatePicker } from "../shells/ExternalIoDatePicker";
import { useValidityCheck } from "../functions/useValidityCheck";

import {
	BothVaccineIndivType,
	DosesVaccineIndivType,
	GroupedScreeningIndivType,
	OnlyVaccineIndivType,
	RadioOptionsIndivType,
	SingleScreeningIndivType,
	ToggleScreeningIndivType
} from "src/types";

export function useHaveParent(
	group?: GroupedScreeningIndivType,
	screening?: ToggleScreeningIndivType | SingleScreeningIndivType | RadioOptionsIndivType,
	vaccine?: BothVaccineIndivType | OnlyVaccineIndivType | DosesVaccineIndivType,
) {
	const hasParent = useMemo(() => {
		if (vaccine === undefined) {
			if (group === undefined) {
				return {
					parent: screening!.id,
					child: screening!.id,
					testType: (screening as ToggleScreeningIndivType | SingleScreeningIndivType).type
				};
			}
			else {
				return {
					parent: group.id,
					child: screening!.id,
					testType: "group and/or radiooptionsindivtype"
				};
			};
		}
		else if (screening === undefined && group === undefined) {
			return {
				parent: vaccine.id,
				child: vaccine.id,
				testType: "vaccine",
			};
		}
		else {
			throw Error("useHaveParent should always have a parent/child combo.")
		};
	}, [group, screening, vaccine]);

	return hasParent;
};

interface IoLinkedDatesProps {
	group?: GroupedScreeningIndivType;
	screening?: ToggleScreeningIndivType | SingleScreeningIndivType | RadioOptionsIndivType;
	vaccine?: BothVaccineIndivType | OnlyVaccineIndivType | DosesVaccineIndivType;
	externalDateReceived: Dayjs | null;
	externalDateDue: Dayjs | null;
	externallinkChecked: boolean;
	handleDateReceived: (
		value: string | null,
		id: string,
		parent: string,
	) => void;
	handleDateDue: (
		value: string | null,
		id: string,
		parent: string,
	) => void;
	handleLinkChecked: (
		value: boolean,
		id: string,
		parent: string,
	) => void;
	slot1?: ReactNode;
	slot2?: ReactNode;
	slot3?: ReactNode;
	slot4?: ReactNode;
};

export function modifyDateAccordingToTest(childId: string, date: Dayjs, testType: string): Dayjs {
	if (childId === "Diabetes") {
		if (testType === "screening") {
			return date.add(1, "year");
		}
		else if (testType === "diagnostic") {
			return date.add(3, "month");
		}
	}
	else if (childId === "Lipid Panel") {
		if (testType === "screening") {
			return date.add(6, "month");
		}
		else if (testType === "diagnostic") {
			return date.add(3, "month");
		}
	}
	else if (childId === "Mammogram") {
		if (testType === "screening") {
			return date.add(1, "year");
		}
		else if (testType === "diagnostic") {
			return date.add(6, "month");
		}
	}
	else if (childId === "DEXA Scan") {
		if (testType === "screening") {
			return date.add(2, "year");
		}
		else if (testType === "diagnostic") {
			return date.add(1, "year");
		}
	}
	else if (childId === "Vision/Glaucoma") {
		if (testType === "screening") {
			return date.add(1, "year");
		}
		else if (testType === "diagnostic") {
			return date.add(6, "month");
		}
	}
	else if (childId === "PSA") {
		if (testType === "screening") {
			return date.add(1, "year");
		}
		else if (testType === "diagnostic") {
			return date.add(6, "month");
		}
	}
	else if (childId === "Depression screening (PHQ-9)" || childId === "Annual Wellness Exam") {
		return date.add(1, "year").add(1, "day");
	}
	else if (childId === "Tetanus") {
		return date.add(10, "year");
	}
	// else {
	return date.add(1, "year");
	// };
};

export function IoLinkedDates({
	group,
	screening,
	vaccine,
	externalDateReceived = null,
	externalDateDue = null,
	externallinkChecked = true,
	handleDateReceived,
	handleDateDue,
	handleLinkChecked,
	slot1,
	slot2,
	slot3,
	slot4,
}: IoLinkedDatesProps) {
	const [dateReceived, setDateReceived] = useState<{ value: Dayjs | null, validationError: string | null }>(() => (
		{
			value: externalDateReceived,
			validationError: null,
		}
	));
	const [linkChecked, setLinkChecked] = useState(() => (externallinkChecked));
	const [dateDue, setDateDue] = useState<{ value: Dayjs | null, validationError: string | null }>(() => (
		{
			value: externalDateDue,
			validationError: null,
		}
	));

	const validity = useValidityCheck(group, screening, vaccine);
	const hasParent = useHaveParent(group, screening, vaccine);

	useEffect(() => {
		if (dateReceived.validationError === null || dateReceived.validationError === "invalidDate") {
			if (dateReceived.value !== null && dateReceived.value.isValid()) {
				const val = dateReceived.value.format('MM/DD/YYYY');
				handleDateReceived(val, hasParent.child, hasParent.parent);
			}
			else {
				handleDateReceived(null, hasParent.child, hasParent.parent);
			};
		};
	}, [dateReceived, handleDateReceived, hasParent.child, hasParent.parent, linkChecked]);

	useEffect(() => {
		if (dateReceived.validationError === null || dateReceived.validationError === "invalidDate") {
			if (linkChecked) {
				if (dateReceived.value !== null && dateReceived.value.isValid()) {
					const moddedVal = modifyDateAccordingToTest(hasParent.child, dateReceived.value, hasParent.testType);
					const val = moddedVal.format('MM/DD/YYYY');
					setDateDue({
						value: dayjs(val),
						validationError: null,
					});
				}
				else {
					setDateDue({
						value: null,
						validationError: "invalidDate",
					});
				};
			};
		};
	}, [dateReceived, hasParent.child, hasParent.testType, linkChecked]);

	useEffect(() => {
		if (dateDue.validationError === null || dateDue.validationError === "invalidDate") {
			if (dateDue.value !== null && dateDue.value.isValid()) {
				const val = dateDue.value.format('MM/DD/YYYY');
				handleDateDue(val, hasParent.child, hasParent.parent);
			}
			else {
				handleDateDue(null, hasParent.child, hasParent.parent);
			};
		};
	}, [dateDue, handleDateDue, hasParent.child, hasParent.parent]);

	// useEffect(() => {
	// }, [linkChecked]);

	const onHandleCheckbox = useCallback((
		_event: ChangeEvent<HTMLInputElement>,
		checked: boolean,
	) => {
		setLinkChecked(checked);
		// handleLinkChecked(checked, hasParent.child, hasParent.parent);
	}, [
		// handleLinkChecked,
		// hasParent.child,
		// hasParent.parent,
	]);

	useEffect(() => {
		handleLinkChecked(linkChecked, hasParent.child, hasParent.parent);
		// if (dateDue.validationError === null || dateDue.validationError === "invalidDate") {
		// 	if (dateDue.value !== null && dateDue.value.isValid()) {
		// 		const val = dateDue.value.format('MM/DD/YYYY');
		// 		handleDateDue(val, hasParent.child, hasParent.parent);
		// 	}
		// 	else {
		// 		handleDateDue(null, hasParent.child, hasParent.parent);
		// 	};
		// };
	}, [linkChecked, handleLinkChecked, hasParent.child, hasParent.parent]);

	// const onHandleCheckbox = (
	// 	_event: ChangeEvent<HTMLInputElement>,
	// 	checked: boolean,
	// ) => {
	// 	setLinkChecked(checked);
	// 	handleLinkChecked(val, hasParent.child, hasParent.parent);
	// };

	return (
		<Stack
			direction="row"
		>
			{slot1}
			<Collapse
				orientation="horizontal"
				in={validity.dateReceived}
				unmountOnExit
			>
				<ExternalIoDatePicker
					label="dateReceived"
					value={dateReceived.value}
					setExternalState={setDateReceived}
				/>
			</Collapse>
			{slot2}
			<Collapse
				orientation="horizontal"
				in={validity.dateDue}
				unmountOnExit
			>
				<Checkbox
					checked={linkChecked}
					onChange={onHandleCheckbox}
					icon={
						<LockOutlinedIcon
							fontSize="small"
						/>
					}
					checkedIcon={
						<LockIcon
							fontSize="small"
						/>
					}
					size="small"
				/>
			</Collapse>
			{slot3}
			<Collapse
				orientation="horizontal"
				in={validity.dateDue}
				unmountOnExit
			>
				<ExternalIoDatePicker
					label="dateDue"
					value={dateDue.value}
					readOnly={linkChecked}
					setExternalState={setDateDue}
				/>
			</Collapse>
			{slot4}
		</Stack>
	);
};
