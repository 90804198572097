import {
	FormLabel,
	FormControl,
	FormGroup,
	Grid,
} from '@mui/material';

import { useSingleDiagnostics } from './SingleDiagnosticsContext';
import { usePhysicalInformation } from 'src/components/physical/physicalinformation/PhysicalInformationContext';

import { SingleDiagnostic } from './SingleDiagnostic';

export function Diagnostics(
) {
	const allSingleDiagnostics = useSingleDiagnostics();
	const physicalInformation = usePhysicalInformation();
	return (
		// <>
		<FormControl
			sx={{
				// marginLeft: 9,
				// paddingBottom: 20,
				// height: "50%",
				overflow: "auto",
				// height: "400px",
				width: "100%"
			}}
			component="fieldset"
			variant="standard"
		>
			<FormLabel
				component="legend"
			>
				Choose diagnostics:
			</FormLabel>
			<FormGroup>
				{allSingleDiagnostics
					.filter(f => (
						f.id === "Diabetes" ||
						f.id === "Lipid Panel" ||
						f.id === "Colon/Cancer" ||
						f.id === "Mammogram" ||
						f.id === "DEXA Scan" ||
						f.id === "PSA" ||
						f.id === "Vision/Glaucoma"
					))
					.filter(f => f.gender === undefined || f.gender === physicalInformation.filter(n => n.id === "patientGender")[0].value)
					.map(sDiagnostic => {
						return (
							<Grid
								key={sDiagnostic.id}
								item
							>
								<SingleDiagnostic
									key={sDiagnostic.id}
									diagnostic={sDiagnostic}
								/>
							</Grid>
						);
					})}
			</FormGroup>
		</FormControl>
		// </>
	);
};
