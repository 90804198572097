import { MouseEvent, ChangeEvent, useCallback } from 'react';
import {
	Collapse,
	Stack,
} from '@mui/material';

import { useSingleDiagnosticsDispatch } from './SingleDiagnosticsContext';
import { SingleDiagnosticIndivType, SingleScreeningIndivType } from 'src/types';

import { IoFormControlLabel } from 'src/io/wrappers/IoFormControlLabel';
import { IoLinkedDates } from 'src/io/wrappers/IoLinkedDates';
import { IoToggleButtonGroup } from 'src/io/wrappers/IoToggleButtonGroup';
import dayjs from 'dayjs';
import { ExternalIoFormControlLabel } from 'src/io/wrappers/ExternalIoFormControlLabel';


interface SingleDiagnosticProps {
	diagnostic: SingleDiagnosticIndivType;
};

export function SingleDiagnostic({
	diagnostic,
}: SingleDiagnosticProps) {
	const dispatch = useSingleDiagnosticsDispatch();

	const handleCheckbox = useCallback(
		(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
			dispatch({
				type: "setCheckbox",
				id: event.target.name,
				value: checked,
			});
		}, [dispatch]
	);

	const handleToggleButtonChange = (
		_event: MouseEvent<HTMLElement, globalThis.MouseEvent>,
		value: string,
		diagnosticId: string,
	) => {
		dispatch({
			type: "toggleDateDueModifier",
			id: diagnosticId,
			value: value,
		});
	};

	const handleDateReceived = useCallback((
		value: string | null,
		id: string,
		parent: string,
	) => {
		dispatch({
			type: "setDateReceived",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);

	const handleDateDue = useCallback((
		value: string | null,
		id: string,
		parent: string,
	) => {
		dispatch({
			type: "setDateDue",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);

	const handleLinkChecked = useCallback((
		value: boolean,
		id: string,
		parent: string,

	) => {
		dispatch({
			type: "setLinkChecked",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);
	
	return (
		<Stack
			direction="row"
			key={diagnostic.id}
		>
			<ExternalIoFormControlLabel
				id={diagnostic.id}
				checked={diagnostic.checked}
				onExternalChange={handleCheckbox}
			/>
			<Collapse
				orientation="horizontal"
				in={diagnostic.checked}
				unmountOnExit
			>
				<IoLinkedDates
					screening={(diagnostic as SingleScreeningIndivType)}
					externalDateReceived={dayjs(diagnostic.dateReceived)}
					externalDateDue={dayjs(diagnostic.dateDue)}
					externallinkChecked={diagnostic.linkChecked}
					handleDateReceived={handleDateReceived}
					handleDateDue={handleDateDue}
					handleLinkChecked={handleLinkChecked}
					slot2={
						<Collapse
							sx={{ marginLeft: 2 }}
							orientation="horizontal"
							in={diagnostic.checked}
							unmountOnExit
						>
							<IoToggleButtonGroup
								screeningOrDiagnostic={(diagnostic as SingleScreeningIndivType)}
								onHandleChange={handleToggleButtonChange}
							/>
							{/* <FormControl
								size="small"
							>
								<ToggleButtonGroup
									size="small"
									value={diagnostic.toggleButtonValue}
									exclusive
									onChange={(event, value) =>
										handleToggleButtonChange(event, value, diagnostic.id)}
								>
									{diagnostic.toggleOptions
										.map(tOption => {
											return (
												<ToggleButton
													key={tOption.id}
													value={tOption.id}
												>
													{tOption.id}
												</ToggleButton>
											);
										})
									}
								</ToggleButtonGroup> */}
							{/* </FormControl> */}
						</Collapse>
					}
				/>
			</Collapse>
		</Stack>
	);
};
