import { MouseEvent, ChangeEvent, useCallback } from 'react';
import {
	Collapse,
	Stack,
} from '@mui/material';

import { useToggledScreeningsDispatch } from 'src/components/screenings/ToggledScreeningsContext';
import { RadioOptionsIndivType, ToggleScreeningIndivType } from 'src/types';

import { IoDatePicker } from 'src/io/shells/IoDatePicker';
import { IoFormControlLabel } from 'src/io/wrappers/IoFormControlLabel';
import { IoLinkedDates } from 'src/io/wrappers/IoLinkedDates';
import { IoToggleButtonGroup } from 'src/io/wrappers/IoToggleButtonGroup';
import dayjs from 'dayjs';
import { ExternalIoFormControlLabel } from 'src/io/wrappers/ExternalIoFormControlLabel';


interface ToggledScreeningProps {
	screening: ToggleScreeningIndivType;
};

export function ToggledScreening({
	screening,
}: ToggledScreeningProps) {
	const dispatch = useToggledScreeningsDispatch();

	const handleCheckbox = useCallback((
		_event: ChangeEvent<HTMLInputElement>,
		checked: boolean,
		id: string,
		which?: string
	) => {
		dispatch({
			type: "setCheckbox",
			id: id,
			value: checked,
			which: which!,
		});
	}, [dispatch])

	const handleToggleButtonChange = (
		_event: MouseEvent<HTMLElement, globalThis.MouseEvent>,
		value: string,
		screeningId: string,
	) => {
		dispatch({
			type: "toggleDateDueModifier",
			id: screeningId,
			value: value,
		});

	};

	const handleDateReceived = useCallback((
		value: string | null,
		id: string,
		parent: string,
	) => {
		dispatch({
			type: "setDateReceived",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);

	const handleDateDue = useCallback((
		value: string | null,
		id: string,
		parent: string,
	) => {
		dispatch({
			type: "setDateDue",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);

	const handleDatePerformed = (
		value: string | null,
		id: string,
		parent: string,
	) => {
		dispatch({
			type: "setDatePerformed",
			id: id,
			value: value,
			parent: parent,
		});
	};

	const handleLinkChecked = useCallback((
		value: boolean,
		id: string,
		parent: string,
	) => {
		dispatch({
			type: "setLinkChecked",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);

	return (
		<Stack
			direction="row"
			key={screening.id}
		>
			<ExternalIoFormControlLabel
				id={screening.id}
				checked={screening.checked}
				onExternalChange={handleCheckbox}
				which="checked"
			/>
			<Collapse
				orientation="horizontal"
				in={screening.checked}
				unmountOnExit
			>
				<IoLinkedDates
					screening={screening}
					externalDateReceived={dayjs(screening.dateReceived)}
					externalDateDue={dayjs(screening.dateDue)}
					externallinkChecked={screening.linkChecked}
					handleDateReceived={handleDateReceived}
					handleDateDue={handleDateDue}
					handleLinkChecked={handleLinkChecked}
					slot2={
						<Collapse
							sx={{ marginLeft: 2 }}
							orientation="horizontal"
							in={screening.checked}
							unmountOnExit
						>
							<IoToggleButtonGroup
								screeningOrDiagnostic={(screening as unknown as RadioOptionsIndivType)}
								onHandleChange={handleToggleButtonChange}
							/>
							{/* <FormControl>
								<ToggleButtonGroup
									size="small"
									value={screening.toggleButtonValue}
									exclusive
									onChange={(event, value) =>
										handleToggleButtonChange(event, value, screening.id)}
								>
									{screening.toggleOptions
										.map(tOption => {
											return (
												<ToggleButton
													key={tOption.id}
													value={tOption.id}
												>
													{tOption.id}
												</ToggleButton>
											);
										})
									}
								</ToggleButtonGroup>
							</FormControl> */}
						</Collapse>
					}
					slot4={
						<>
							<Collapse
								orientation="horizontal"
								in={(screening.checked) &&
									(screening.toggleButtonValue === "HYSTERECTOMY")}
								unmountOnExit
							>
								<IoFormControlLabel
									id="dateUnknown"
									which="dateUnknown"
									onExternalChange={(
										event: ChangeEvent<HTMLInputElement>,
										checked: boolean,
										id: string,
										which?: string,
									) =>
										handleCheckbox(event, checked, screening.id, which)}
								/>
							</Collapse>
							<Collapse
								orientation="horizontal"
								in={(screening.checked) &&
									(screening.toggleButtonValue === "HYSTERECTOMY") &&
									(screening.dateUnknown === false)}
								unmountOnExit
							>
								<IoDatePicker
									id={screening.id}
									label="Date Performed"
									onHandleChange={(value, id) =>
										handleDatePerformed(value, id, screening.id)}
								/>
							</Collapse>
						</>
					}
				/>
			</Collapse>
		</Stack>
	);
};
