import { ChangeEvent, useMemo } from 'react';
import { Box, TextField } from '@mui/material';
import { IoInputProps } from 'src/types';

interface ExternalIoTextFieldProps {
	id: string,
	label: string,
	value: string,
	setExternalState: (
		value: string,
		id: string,
	) => void;
	// onHandleChange: (
	// 	value: string,
	// 	id: string,
	// 	error?: boolean,
	// ) => void,
};

export function ExternalIoTextField({
	id,
	label,
	value,
	setExternalState,
	// onHandleChange,
}: ExternalIoTextFieldProps) {
	// const [value, setValue] = useState("");
	// const [error, setError] = useState(true);
	const inputProps: IoInputProps = useMemo(() => {
		return {
			"data-testid": `${id ? id : "lolz"}-textfield`,
			// "aria-label": "controlled",
		};
	}, [id]);

	const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newValue = event.target.value;
		setExternalState(newValue, id);
		// if (newValue.length === 0) {
		// 	setError(true);
		// 	setExternalState(newValue);
		// }
		// else {
		// 	setError(false);
		// 	setExternalState(newValue);
		// }
	};

	// const onBlur = (_event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
	// 	onHandleChange(error ? "" : value, id, error);
	// };

	return (
		<Box
			component="form"
			sx={{
				'& > :not(style)': { width: '25ch' },
				margin: 0,
			}}

			autoComplete="off"
		>
			<TextField
				inputProps={inputProps}
				id={id}
				label={label}
				variant="filled"
				value={value}
				// error={error}
				onChange={onChange}
			// onBlur={onBlur}
			/>
		</Box>
	);
};
