import { ChangeEvent, Fragment, useCallback } from 'react';
import {
	Box,
	FormLabel,
	FormControl,
	FormGroup,
} from '@mui/material';

import { useMedications, useMedicationsDispatch } from 'src/components/medications/MedicationsContext';

import { ExternalIoFormControlLabel } from 'src/io/wrappers/ExternalIoFormControlLabel';

interface MedicationsProps {
	// mode: string,
};

// eslint-disable-next-line no-empty-pattern
export function Medications({
	// mode,
}: MedicationsProps) {
	const allMedications = useMedications();
	const dispatch = useMedicationsDispatch();

	const handleCheckbox = useCallback(
		(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
			dispatch({
				type: "setCheckbox",
				id: event.target.name,
				value: checked,
			});
		}, [dispatch]
	);

	return (
		// <
		// Box
		// sx={{
		// 	display: 'flex',
		// 	overflow: "auto",
		// 	height: "400px",
		// 	width: "100%"
		// }}
		// >
		<FormControl
			sx={{
				// marginLeft: 9,
				// paddingBottom: 20,
				// height: "50%",
				overflow: "auto",
				// height: "400px",
				width: "100%"
			}}
			component="fieldset"
			variant="standard"
		>
			<FormLabel
				component="legend"
			>
				Choose medications:
			</FormLabel>
			<FormGroup>
				{allMedications.map(medication => {
					return (
						<Fragment
							key={medication.id}
						>
							<ExternalIoFormControlLabel
								id={medication.label}
								checked={medication.checked}
								onExternalChange={handleCheckbox}
							/>
						</Fragment>
					);
				})}
			</FormGroup>
		</FormControl>
		// </
		// Box
		// >
	);
};
