import { createContext, Dispatch, ReactNode, useContext } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import { DosesVaccineDraftType } from "src/types";
import { ZodDosesVaccineDraftType } from "src/types_zod";

import base from 'src/public/physical/vaccines/dosesvaccines.json';

parseZodReaderFriendly(ZodDosesVaccineDraftType, base);

const init = base as DosesVaccineDraftType;

export const DosesVaccinesContext = createContext<DosesVaccineDraftType | null>(null);
export const DosesVaccinesDispatchContext = createContext<Dispatch<DosesVaccinesActionType> | null>(null);

export function useDosesVaccines() {
	return useContext(DosesVaccinesContext)!;
};

export function useDosesVaccinesDispatch() {
	return useContext(DosesVaccinesDispatchContext)!;
};

export interface DosesVaccineInitActionType {
	type: string;
	value: DosesVaccineDraftType;
};

export interface DosesVaccinesCheckboxActionType {
	type: string;
	id: string;
	value: boolean;
	parent: string;
};
export interface DosesVaccinesDateReceivedActionType {
	type: string;
	id: string;
	value: string | null;
	parent: string;
};

export type DosesVaccinesActionType =
	DosesVaccineInitActionType
	| DosesVaccinesCheckboxActionType
	| DosesVaccinesDateReceivedActionType;

function dosesVaccinesReducer(draft: Draft<DosesVaccineDraftType>, action: DosesVaccinesActionType) {
	switch (action.type) {
		case 'initialize': {
			const narrowedAction = action as DosesVaccineInitActionType;
			return narrowedAction.value;
		}
		case 'setCheckbox': {
			const narrowedAction = action as DosesVaccinesCheckboxActionType;

			const vaccine = draft.filter(n => n.id === narrowedAction.parent)[0];
			if (narrowedAction.id === narrowedAction.parent) {
				vaccine.checked = narrowedAction.value;
				vaccine.doses = vaccine.doses.map(dose => {
					return {
						...dose,
						dateReceived: null,
						checked: false,
					}
				});
			}
			else {
				const dose = vaccine.doses.filter(p => p.id === narrowedAction.id)[0];
				dose.checked = narrowedAction.value;
				dose.dateReceived = null;
			}

			break;
		}
		case 'setDateReceived': {
			const narrowedAction = action as DosesVaccinesDateReceivedActionType;

			const vaccine = draft.filter(n => n.id === narrowedAction.parent)[0];
			const dose = vaccine.doses.filter(p => p.id === narrowedAction.id)[0];
			dose.dateReceived = narrowedAction.value;
			break;
		}
		default: {
			break;
		}
	};
};

interface DosesVaccinesPhysicianProps {
	children: ReactNode
	testingInit?: DosesVaccineDraftType,
};

export function DosesVaccinesProvider({
	children,
	testingInit,
}: DosesVaccinesPhysicianProps) {
	const [dosesVaccines, dispatch] = useImmerReducer(dosesVaccinesReducer, testingInit ? testingInit : init as DosesVaccineDraftType);

	// useEffect(() => {
	// 	let ignore = false;
	// 	import(
	// 		/* webpackChunkName: "dosesvaccines" */
	// 		`../../../public/physical/vaccines/dosesvaccines.json`
	// 	)
	// 		.then(({ default: myJson }: { default: DosesVaccineDraftType }) => {
	// 			if (!ignore) {
	// 				dispatch({
	// 					type: "initialize",
	// 					value: myJson
	// 				});
	// 			};
	// 		})
	// 		.catch(err => console.log("err: ", err));

	// 	return () => {
	// 		ignore = true;
	// 	};
	// }, [dispatch]);

	return (
		<DosesVaccinesContext.Provider value={dosesVaccines}>
			<DosesVaccinesDispatchContext.Provider value={dispatch}>
				{children}
			</DosesVaccinesDispatchContext.Provider>
		</DosesVaccinesContext.Provider>
	);
};
