import { createContext, Dispatch, ReactNode, useContext } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { AgeRangeInitObjType, AgeRangeType, PneumococcalVaccineDraftType } from "src/types";
import { ZodAgeRangeType, ZodPneumococcalVaccineDraftType, ZodPneumococcalVaccineLocalCopyDraftType } from "src/types_zod";
import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import pneumobase from 'src/public/physical/vaccines/pneumococcalvaccine/base.json';
import pneumofourmonthstosixmonths from 'src/public/physical/vaccines/pneumococcalvaccine/fourmonthstosixmonths.json';
import pneumosevenmonthstoelevenmonths from 'src/public/physical/vaccines/pneumococcalvaccine/sevenmonthstoelevenmonths.json';
import pneumotwelvemonthstotwentythreemonths from 'src/public/physical/vaccines/pneumococcalvaccine/twelvemonthstotwentythreemonths.json';
import pneumotwentyfourmonthstofiftyninemonths from 'src/public/physical/vaccines/pneumococcalvaccine/twentyfourmonthstofiftyninemonths.json';
import pneumosixtoeighteen from 'src/public/physical/vaccines/pneumococcalvaccine/sixtoeighteen.json';
import pneumonineteentosixtyfour from 'src/public/physical/vaccines/pneumococcalvaccine/nineteentosixtyfour.json';
import pneumosixtyfiveandup from 'src/public/physical/vaccines/pneumococcalvaccine/sixtyfiveandup.json';

parseZodReaderFriendly(ZodPneumococcalVaccineDraftType, pneumobase);
parseZodReaderFriendly(ZodAgeRangeType, pneumofourmonthstosixmonths);
parseZodReaderFriendly(ZodAgeRangeType, pneumosevenmonthstoelevenmonths);
parseZodReaderFriendly(ZodAgeRangeType, pneumotwelvemonthstotwentythreemonths);
parseZodReaderFriendly(ZodAgeRangeType, pneumotwentyfourmonthstofiftyninemonths);
parseZodReaderFriendly(ZodAgeRangeType, pneumosixtoeighteen);
parseZodReaderFriendly(ZodAgeRangeType, pneumonineteentosixtyfour);
parseZodReaderFriendly(ZodAgeRangeType, pneumosixtyfiveandup);

const init = pneumobase as PneumococcalVaccineDraftType;
const ageRangeInits: AgeRangeInitObjType = {
	pneumofourmonthstosixmonths,
	pneumosevenmonthstoelevenmonths,
	pneumotwelvemonthstotwentythreemonths,
	pneumotwentyfourmonthstofiftyninemonths,
	pneumosixtoeighteen,
	pneumonineteentosixtyfour,
	pneumosixtyfiveandup,
};

export const PneumococcalVaccineContext = createContext<PneumococcalVaccineDraftType | null>(null);
export const PneumococcalVaccineDispatchContext = createContext<Dispatch<PneumococcalVaccineActionType> | null>(null);

export function usePneumococcalVaccine() {
	return useContext(PneumococcalVaccineContext)!;
};

export function usePneumococcalVaccineDispatch() {
	return useContext(PneumococcalVaccineDispatchContext)!;
};

export interface PneumococcalVaccineInitActionType {
	type: string;
	value: string;
};

export interface PneumococcalVaccineInitRecommendationsActionType {
	type: string;
	value: AgeRangeType;
};

export interface PneumococcalVaccineSetNewStateActionType {
	type: string;
	value: PneumococcalVaccineDraftType;
};

export interface PneumococcalVaccineClearStateCauseDialogClosedNotSubmittedActionType {
	type: string;
};

export interface PneumococcalVaccineSetInvalidAgeKeyActionType {
	type: string;
	value: boolean;
};

export interface PneumococcalVaccineSetAgeRangeKeyActionType {
	type: string;
	value: string;
};

export interface PneumococcalVaccineCheckboxActionType {
	type: string;
	value: boolean;
};

export interface PneumococcalVaccineDateReceivedActionType {
	type: string;
	id: string;
	value: string | null;
};

export type PneumococcalVaccineActionType =
	PneumococcalVaccineInitActionType
	| PneumococcalVaccineInitRecommendationsActionType
	| PneumococcalVaccineSetNewStateActionType
	| PneumococcalVaccineClearStateCauseDialogClosedNotSubmittedActionType
	| PneumococcalVaccineSetInvalidAgeKeyActionType
	| PneumococcalVaccineSetAgeRangeKeyActionType
	| PneumococcalVaccineCheckboxActionType
	| PneumococcalVaccineDateReceivedActionType;

/**
 * initial state has properties set to null and reset triggers error unless checked beforehand
 */
function resetExternalStateProperty(draftProperty: AgeRangeType | null) {
	if (draftProperty !== null) {
		draftProperty = {
			riskConditions: draftProperty.riskConditions.map(riskCondition => {
				return {
					...riskCondition,
					checked: false,
				}
			}),
			recommendations: draftProperty.recommendations.map(recommendation => {
				return {
					...recommendation,
					possibleNextVaccines: recommendation.possibleNextVaccines.map(possibleNextVaccine => {
						return {
							...possibleNextVaccine,
							statements: possibleNextVaccine.statements.map(statement => {
								return {
									...statement,
									value: (recommendation.id === "novaccinestaken" &&
										statement.id === "no risk conditions"),
								};
							}),
						};
					}),
				};
			}),
		};
	};
};

/**
 * differs from all resetLocalState functions since assigning to self, as opposed to from prev to draft
 */
function resetExternalState(draft: Draft<PneumococcalVaccineDraftType>) {
	// draft.checked = false;
	draft.versions = [];
	resetExternalStateProperty(draft.fourmonthstosixmonths);
	resetExternalStateProperty(draft.sevenmonthstoelevenmonths);
	resetExternalStateProperty(draft.twelvemonthstotwentythreemonths);
	resetExternalStateProperty(draft.twentyfourmonthstofiftyninemonths);
	resetExternalStateProperty(draft.sixtoeighteen);
	resetExternalStateProperty(draft.nineteentosixtyfour);
	resetExternalStateProperty(draft.sixtyfiveandup);
};

function pneumococcalVaccineReducer(draft: Draft<PneumococcalVaccineDraftType>, action: PneumococcalVaccineActionType) {
	switch (action.type) {
		case 'changeInitState': {
			const narrowedAction = action as PneumococcalVaccineInitActionType;
			draft[narrowedAction.value] = ageRangeInits[`pneumo${narrowedAction.value}`] as AgeRangeType
			break;
		}
		case 'setExternalStateToSubmittedState': {
			const narrowedAction = action as PneumococcalVaccineSetNewStateActionType;
			parseZodReaderFriendly(ZodPneumococcalVaccineLocalCopyDraftType, narrowedAction.value);

			const updatedVersions = narrowedAction.value.versions.map(version => {
				return version;
			});

			const updatedNarrowedActionValue = {
				...narrowedAction.value,
				versions: updatedVersions,
			};

			const toCheckV = {
				...updatedNarrowedActionValue,
				invalidAge: draft.invalidAge,
				ageRange: draft.ageRange,
				checked: true,
			};
			parseZodReaderFriendly(ZodPneumococcalVaccineDraftType, toCheckV);
			return toCheckV;
		}
		case 'clearStateCauseDialogClosedNotSubmitted': {
			resetExternalState(draft);
			parseZodReaderFriendly(ZodPneumococcalVaccineDraftType, draft);

			break;
		}
		case "setInValidAge": {
			const narrowedAction = action as PneumococcalVaccineSetInvalidAgeKeyActionType;
			draft.invalidAge = narrowedAction.value;

			break;
		}
		case "setAgeRangeKey": {
			const narrowedAction = action as PneumococcalVaccineSetAgeRangeKeyActionType;
			draft.ageRange = narrowedAction.value;

			break;
		}
		case 'setCheckbox': {
			const narrowedAction = action as PneumococcalVaccineCheckboxActionType;
			draft.checked = narrowedAction.value;
			resetExternalState(draft);
			break;
		}
		default: {
			break;
		}
	};
};

export interface PneumococcalVaccinePhysicianProps {
	children: ReactNode,
	testingInit?: PneumococcalVaccineDraftType,
};

export function PneumococcalVaccineProvider({
	children,
	testingInit,
}: PneumococcalVaccinePhysicianProps) {
	const [pneumococcalVaccine, dispatch] = useImmerReducer(pneumococcalVaccineReducer, testingInit ? testingInit : init as PneumococcalVaccineDraftType);

	return (
		<PneumococcalVaccineContext.Provider value={pneumococcalVaccine}>
			<PneumococcalVaccineDispatchContext.Provider value={dispatch}>
				{children}
			</PneumococcalVaccineDispatchContext.Provider>
		</PneumococcalVaccineContext.Provider>
	);
};
