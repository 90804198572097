import { createContext, ReactNode, useContext, Dispatch } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import { DemographicsDraftType, DemographicsIndivType } from "src/types";
import { ZodDemographicsDraftType } from "src/types_zod";

import base from 'src/public/administrative/demographics.json';
import dayjs from "dayjs";

parseZodReaderFriendly(ZodDemographicsDraftType, base);

const init = base.map(b => {
	if (b.id === "dateOfRevision") {
		return {
			...b,
			value: dayjs().format('MM/DD/YYYY'),
		};
	};

	return b;
})

export const DemographicsContext = createContext<DemographicsDraftType | null>(null);
export const DemographicsDispatchContext = createContext<Dispatch<DemographicsActionType> | null>(null);

export function useDemographics() {
	return useContext(DemographicsContext)!;
};

export function useDemographicsDispatch() {
	return useContext(DemographicsDispatchContext)!;
};

export interface DemographicsInitActionType {
	type: string;
	value: DemographicsDraftType;
};

export interface DemographicsStrActionType {
	type: string,
	id: string,
	value: string,
};

export interface DemographicsNumActionType {
	type: string,
	id: string,
	value: number,
};

export interface DemographicsDateActionType {
	type: string;
	id: string,
	value: string | null;
};

export type DemographicsActionType =
	| DemographicsInitActionType
	| DemographicsStrActionType
	| DemographicsNumActionType
	| DemographicsDateActionType;

function demographicsReducer(draft: Draft<DemographicsDraftType>, action: DemographicsActionType) {
	switch (action.type) {
		case 'changeInitState': {
			const narrowedAction = action as DemographicsInitActionType;
			return narrowedAction.value;
		}
		case 'setTextChange': {
			const narrowedAction = action as DemographicsStrActionType;
			const demographicsItem = draft.filter(n => n.id === narrowedAction.id)[0] as DemographicsIndivType;

			demographicsItem.value = narrowedAction.value;
			break;
		}
		case 'setNumChange': {
			const narrowedAction = action as DemographicsStrActionType;
			const demographicsItem = draft.filter(n => n.id === narrowedAction.id)[0] as DemographicsIndivType;

			demographicsItem.value = narrowedAction.value;
			break;
		}
		case 'setDateChange': {
			const narrowedAction = action as DemographicsDateActionType;
			const demographicsItem = draft.filter(n => n.id === narrowedAction.id)[0] as DemographicsIndivType;

			demographicsItem.value = narrowedAction.value;
			break;
		}
		default: {
			return draft;
		}
	};
};

interface DemographicsPhysicianProps {
	children: ReactNode,
	testingInit?: DemographicsDraftType,
};

export function DemographicsProvider({
	children,
	testingInit,
}: DemographicsPhysicianProps) {
	const [demographics, dispatch] = useImmerReducer(demographicsReducer, testingInit ? testingInit : init as DemographicsDraftType);

	return (
		<DemographicsContext.Provider value={demographics}>
			<DemographicsDispatchContext.Provider value={dispatch}>
				{children}
			</DemographicsDispatchContext.Provider>
		</DemographicsContext.Provider>
	);
};
