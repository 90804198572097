import { useState, ChangeEvent, MouseEvent, ReactElement } from 'react';
import {
	Stack,
	CssBaseline,
	GlobalStyles,
	Button,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DemographicsProvider, useDemographics } from './components/administrative/demographics/DemographicsContext';
import { AppointmentsProvider, useAppointments } from './components/administrative/appointments/AppointmentsContext';
import { PhysiciansProvider, usePhysicians } from './components/administrative/physicians/PhysiciansContext';
import { MedicationsBrokenDownDraftType, MedicationsProvider, useMedications } from './components/medications/MedicationsContext';
import { MedicationAllergiesProvider, useMedicationAllergies } from './components/medications/MedicationAllergiesContext';
import { PhysicalInformationProvider, usePhysicalInformation } from './components/physical/physicalinformation/PhysicalInformationContext';
import { PneumococcalVaccineProvider, usePneumococcalVaccine } from './components/physical/vaccines/PneumococcalVaccine/PneumococcalVaccineContext';
import { BothVaccinesProvider, useBothVaccines } from './components/physical/vaccines/BothVaccinesContext';
import { DosesVaccinesProvider, useDosesVaccines } from './components/physical/vaccines/DosesVaccinesContext';
import { OnlyVaccinesProvider, useOnlyVaccines } from './components/physical/vaccines/OnlyVaccinesContext';
import { GroupScreeningsProvider, useGroupScreenings } from './components/screenings/GroupScreeningsContext';
import { SingleScreeningsProvider, useSingleScreenings } from './components/screenings/SingleScreeningsContext';
import { ToggledScreeningsProvider, useToggledScreenings } from './components/screenings/ToggledScreeningsContext';
import { FunctionalsProvider, useFunctionals } from './components/functionals/FunctionalsContext';
import { ConditionsProvider, useConditions } from './components/conditionsanddiseases/conditions/ConditionsContext';
import { SingleDiagnosticsProvider, useSingleDiagnostics } from './components/conditionsanddiseases/diagnostics/SingleDiagnosticsContext';
import { SocialDrugHistoryProvider, useSocialDrugHistory } from './components/conditionsanddiseases/socialdrughistory/SocialDrugHistoryContext';
import { SocialDeterminantsProvider, useSocialDeterminants } from './components/socialdeterminants/SocialDeterminantsContext';

import { CCPButton } from './generation/CCP/CCPButton';
import { HBA1CButton } from './generation/HBA1C/HBA1CButton';

// import { IoMode } from './io/wrappers/IoMode';
import { IoVerticalTabs } from './io/wrappers/ioVerticalTabs';

import {
	DemographicsDraftType,
	AppointmentDraftType,
	PhysiciansDraftType,
	MedicationAllergiesDraftType,
	PhysicalInformationDraftType,
	PneumococcalVaccineDraftType,
	BothVaccineDraftType,
	DosesVaccineDraftType,
	OnlyVaccineDraftType,
	GroupedScreeningDraftType,
	SingleScreeningDraftType,
	ToggledScreeningDraftType,
	FunctionalsDraftType,
	CheckedConditionsDraftType,
	SingleDiagnosticDraftType,
	SocialDrugHistoryDraftType,
	SocialDeterminantsDraftType,
} from './types';

import './App.css';

function useAllLoaded(
	demographics: DemographicsDraftType,
	appointments: AppointmentDraftType,
	physicians: PhysiciansDraftType,
	medications: MedicationsBrokenDownDraftType,
	medicationAllergies: MedicationAllergiesDraftType,
	physicalInformation: PhysicalInformationDraftType,
	pneumococcalVaccine: PneumococcalVaccineDraftType,
	allBothVaccines: BothVaccineDraftType,
	allDosesVaccines: DosesVaccineDraftType,
	allOnlyVaccines: OnlyVaccineDraftType,
	allGroupScreenings: GroupedScreeningDraftType,
	allSingleScreenings: SingleScreeningDraftType,
	allToggleScreenings: ToggledScreeningDraftType,
	functionals: FunctionalsDraftType,
	conditions: CheckedConditionsDraftType,
	allSingleDiagnostics: SingleDiagnosticDraftType,
	socialDrugHistory: SocialDrugHistoryDraftType,
	socialDeterminants: SocialDeterminantsDraftType,
) {
	if (
		demographics.length > 0 &&
		appointments.length > 0 &&
		physicians.length > 0 &&
		medications.length > 0 &&
		medicationAllergies.length > 0 &&
		physicalInformation.length > 0 &&
		pneumococcalVaccine !== null &&
		allBothVaccines.length > 0 &&
		allDosesVaccines.length > 0 &&
		allOnlyVaccines.length > 0 &&
		allGroupScreenings.length > 0 &&
		allSingleScreenings.length > 0 &&
		allToggleScreenings.length > 0 &&
		functionals !== null &&
		conditions.length > 0 &&
		allSingleDiagnostics.length > 0 &&
		socialDrugHistory.length > 0 &&
		socialDeterminants.length > 0
	) {
		return true;
	};

	return false;
};

function AppContents() {
	// const [mode, setMode] = useState<string>("All");

	const demographics = useDemographics();
	const appointments = useAppointments();
	const physicians = usePhysicians();
	const medications = useMedications();
	const medicationAllergies = useMedicationAllergies();
	const physicalInformation = usePhysicalInformation();
	const pneumococcalVaccine = usePneumococcalVaccine();
	const allBothVaccines = useBothVaccines();
	const allDosesVaccines = useDosesVaccines();
	const allOnlyVaccines = useOnlyVaccines();
	const allGroupScreenings = useGroupScreenings();
	const allSingleScreenings = useSingleScreenings();
	const allToggleScreenings = useToggledScreenings();
	const functionals = useFunctionals();
	const conditions = useConditions();
	const allSingleDiagnostics = useSingleDiagnostics();
	const socialDrugHistory = useSocialDrugHistory();
	const socialDeterminants = useSocialDeterminants();

	const allLoaded = useAllLoaded(
		demographics,
		appointments,
		physicians,
		medications,
		medicationAllergies,
		physicalInformation,
		pneumococcalVaccine,
		allBothVaccines,
		allDosesVaccines,
		allOnlyVaccines,
		allGroupScreenings,
		allSingleScreenings,
		allToggleScreenings,
		functionals,
		conditions,
		allSingleDiagnostics,
		socialDrugHistory,
		socialDeterminants,
	);

	// useEffect(() => {
	// 	console.log(`dateOfEstablishment: ${demographics.filter(d => d.id === "dateOfEstablishment")[0].value}`);
	// }, [demographics]);

	return (
		<Stack
			id="toplevelstack"
			sx={{
				height: "100%",
				justifyContent: "space-between",
			}}
			rowGap={2}
		>
			{allLoaded ?
				<>
					<Stack
						id="toplevelstack-row1"
						// direction="row"
						sx={{
							height: `92vh`,
						}}
					>
						{/* <IoMode
							value={mode}
							onHandleChange={onHandleChange}
						/> */}
						<IoVerticalTabs
							isTesting={false}
						// mode={mode}
						// functionals={functionals}
						/>
					</Stack>
					<Stack
						id="toplevelstack-row2"
						direction="row"
					// sx={{
					// height: `5vh`,
					// backgroundColor: backgroundColor, // covered by App.backgroundColor -> CssBaseline.GlobalStyles.styles.body.backgroundColor
					// }}
					>
						<CCPButton
						// mode={mode}
						/>
						<HBA1CButton
						/>
					</Stack>
				</> :
				<>...Loading</>
			}
		</Stack>
	);
};
interface AppProps {
	children?: ReactElement
};

function App({
	children
}: AppProps) {
	const backgroundColor = "#DEDEDE";

	return (
		<CssBaseline>
			<GlobalStyles
				styles={{
					body: { backgroundColor: backgroundColor },
				}}
			/>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DemographicsProvider>
					<AppointmentsProvider>
						<PhysiciansProvider>
							<MedicationsProvider>
								<MedicationAllergiesProvider>
									<PhysicalInformationProvider>
										<PneumococcalVaccineProvider>
											<BothVaccinesProvider>
												<DosesVaccinesProvider>
													<OnlyVaccinesProvider>
														<GroupScreeningsProvider>
															<SingleScreeningsProvider>
																<ToggledScreeningsProvider>
																	<FunctionalsProvider>
																		<ConditionsProvider>
																			<SingleDiagnosticsProvider>
																				<SocialDrugHistoryProvider>
																					<SocialDeterminantsProvider>
																						<AppContents />
																					</SocialDeterminantsProvider>
																				</SocialDrugHistoryProvider>
																			</SingleDiagnosticsProvider>
																		</ConditionsProvider>
																	</FunctionalsProvider>
																</ToggledScreeningsProvider>
															</SingleScreeningsProvider>
														</GroupScreeningsProvider>
													</OnlyVaccinesProvider>
												</DosesVaccinesProvider>
											</BothVaccinesProvider>
										</PneumococcalVaccineProvider>
									</PhysicalInformationProvider>
								</MedicationAllergiesProvider>
							</MedicationsProvider>
						</PhysiciansProvider>
					</AppointmentsProvider>
				</DemographicsProvider>
			</LocalizationProvider>
		</CssBaseline>
	);
};

export default App;
