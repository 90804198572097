import { MouseEvent, ChangeEvent, useCallback } from 'react';
import {
	FormControl,
	Collapse,
	RadioGroup,
	FormControlLabel,
	Radio,
	Stack,
} from '@mui/material';

import { useGroupScreeningsDispatch } from 'src/components/screenings/GroupScreeningsContext';
import { GroupedScreeningIndivType } from 'src/types';

import { IoFormControlLabel } from 'src/io/wrappers/IoFormControlLabel';
import { IoLinkedDates } from 'src/io/wrappers/IoLinkedDates';
import { IoToggleButtonGroup } from 'src/io/wrappers/IoToggleButtonGroup';
import dayjs from 'dayjs';
import { ExternalIoFormControlLabel } from 'src/io/wrappers/ExternalIoFormControlLabel';

interface GroupedScreeningProps {
	group: GroupedScreeningIndivType;
};

export function GroupedScreening({
	group,
}: GroupedScreeningProps) {
	const dispatch = useGroupScreeningsDispatch();

	const handleCheckbox = useCallback(
		(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
			dispatch({
				type: "setCheckbox",
				id: event.target.name,
				value: checked,
			});
		}, [dispatch]
	);

	const handleRadioButtonChange = useCallback((
		_event: ChangeEvent<HTMLInputElement>,
		id: string,
		groupId: string,
	) => {
		dispatch({
			type: "setScreeningInGroup",
			id: id,
			parent: groupId,
		});

	}, [dispatch])

	const handleToggleButtonChange = useCallback((
		_event: MouseEvent<HTMLElement, globalThis.MouseEvent>,
		value: string,
		screeningId: string,
		groupId?: string,
	) => {
		dispatch({
			type: "toggleDateDueModifier",
			id: screeningId,
			value: value,
			parent: groupId!,
		});
	}, [dispatch]);

	const handleDateReceived = useCallback((
		value: string | null,
		id: string,
		parent: string,
	) => {
		// console.count("GroupedScreening.handleDateReceived")
		dispatch({
			type: "setDateReceivedinGroup",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);

	const handleDateDue = useCallback((
		value: string | null,
		id: string,
		parent: string,
	) => {
		// console.count("GroupedScreening.handleDateDue")
		dispatch({
			type: "setDateDueInGroup",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);
	
	const handleLinkChecked = useCallback((
		value: boolean,
		id: string,
		parent: string,

	) => {
		dispatch({
			type: "setLinkChecked",
			id: id,
			value: value,
			parent: parent,
		});
	}, [dispatch]);

	return (
		<Stack
			direction="row"
			key={group.id}
		>
			<ExternalIoFormControlLabel
				id={group.id}
				checked={group.checked}
				onExternalChange={handleCheckbox}
			/>
			<Collapse
				orientation="horizontal"
				in={group.checked}
				unmountOnExit
			>
				<FormControl>
					<RadioGroup
						name={group.id}
						value={group.radioButtonValue}
						onChange={(event, value) =>
							handleRadioButtonChange(event, value, group.id)}
					>
						{group.radioOptions
							.map(screening => {
								return (
									<Stack
										direction="row"
										key={screening.id}
									>
										<FormControlLabel
											value={screening.id}
											control={<Radio size="small" />}
											label={screening.id}
										/>
										<IoLinkedDates
											group={group}
											screening={screening}
											externalDateReceived={dayjs(screening.dateReceived)}
											externalDateDue={dayjs(screening.dateDue)}
											externallinkChecked={screening.linkChecked}						
											handleDateReceived={handleDateReceived}
											handleDateDue={handleDateDue}
											handleLinkChecked={handleLinkChecked}
											slot2={
												<Collapse
													sx={{ marginLeft: 2 }}
													orientation="horizontal"
													in={group.checked &&
														(group.radioButtonValue === screening.id)}
													unmountOnExit
												>
													<IoToggleButtonGroup
														screeningOrDiagnostic={screening}
														group={group}
														onHandleChange={handleToggleButtonChange}
													/>
													{/* <FormControl>
														<ToggleButtonGroup
															size="small"
															value={screening.toggleButtonValue}
															exclusive
															onChange={(event, value) =>
																handleToggleButtonChange(event, value, screening.id, group.id)}
														>
															{screening.toggleOptions
																.map(tOption => {
																	return (
																		<ToggleButton
																			key={tOption.id}
																			value={tOption.id}
																		>
																			{tOption.id}
																		</ToggleButton>
																	);
																})
															}
														</ToggleButtonGroup>
													</FormControl> */}
												</Collapse>
											}
										/>
									</Stack>
								);
							})
						}
					</RadioGroup>
				</FormControl>
			</Collapse>
		</Stack>
	);
};
