import { createContext, ReactNode, useContext, Dispatch } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { FunctionalsDraftType } from "src/types";

import base from 'src/public/functionals/base.json';
import diet from 'src/public/functionals/diet.json';
import KATZADL from 'src/public/functionals/KATZADL.json';
import LBIADL from 'src/public/functionals/LBIADL.json';
import physicalactivity from 'src/public/functionals/physicalactivity.json';
import socialactivity from 'src/public/functionals/socialactivity.json';

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";
import {
	ZodDietType,
	ZodFunctionalsDraftType,
	ZodKATZADLType,
	ZodLBIADLType,
	ZodPhysicalActivityType,
	ZodSocialActivityType,
} from "src/types_zod";

parseZodReaderFriendly(ZodFunctionalsDraftType, base);
parseZodReaderFriendly(ZodDietType, diet);
parseZodReaderFriendly(ZodKATZADLType, KATZADL);
parseZodReaderFriendly(ZodLBIADLType, LBIADL);
parseZodReaderFriendly(ZodPhysicalActivityType, physicalactivity);
parseZodReaderFriendly(ZodSocialActivityType, socialactivity);

const init = base as FunctionalsDraftType;
init.diet = diet;
init.KATZADL = KATZADL;
init.LBIADL = LBIADL;
init.physicalactivity = physicalactivity;
init.socialactivity = socialactivity;

export const FunctionalsContext = createContext<FunctionalsDraftType | null>(null);
export const FunctionalsDispatchContext = createContext<Dispatch<FunctionalsActionType> | null>(null);

export function useFunctionals() {
	return useContext(FunctionalsContext)!;
};

export function useFunctionalsDispatch() {
	return useContext(FunctionalsDispatchContext)!;
};

// export interface FunctionalsInitActionType {
// 	type: string;
// 	value: string;
// };

export interface FunctionalsSetNewStateActionType {
	type: string;
	value: FunctionalsDraftType;
};

export interface FunctionalsClearStateCauseDialogClosedNotSubmittedActionType {
	type: string;
};

export interface FunctionalsCheckboxActionType {
	type: string,
	id: string,
	value: boolean,
};

export interface FunctionalsSwitchActivityActionType {
	type: string,
	id: string,
	value: boolean,
};

export type FunctionalsActionType =
	// | FunctionalsInitActionType
	| FunctionalsSetNewStateActionType
	| FunctionalsClearStateCauseDialogClosedNotSubmittedActionType
	| FunctionalsCheckboxActionType
	| FunctionalsSwitchActivityActionType;

/*
* initial state has properties set to null and reset triggers error unless checked beforehand
*/
function resetExternalStateProperty(draft: FunctionalsDraftType, externalStateProperty: string,) {
	if (draft[externalStateProperty] !== null) {
		if (externalStateProperty === "diet") {
			draft[externalStateProperty] = {
				...draft[externalStateProperty]!,
				questions: draft[externalStateProperty]!.questions.map(question => {
					return {
						...question,
						value: 0,
					};
				}),
			};
		}
		else if (externalStateProperty === "KATZADL") {
			draft[externalStateProperty] = {
				...draft[externalStateProperty]!,
				total: 0,
				activities: draft[externalStateProperty]!.activities.map(activity => {
					return {
						...activity,
						checked: false,
					};
				}),
			};
		}
		else if (externalStateProperty === "LBIADL") {
			draft[externalStateProperty] = {
				...draft[externalStateProperty]!,
				total: 0,
				activities: draft[externalStateProperty]!.activities.map(activity => {
					return {
						...activity,
						selectedvalue: 0,
						options: activity.options.map(option => {
							return {
								...option,
								checked: false,
							};
						}),
					};
				}),
			};
		}
		else if (externalStateProperty === "physicalactivity") {
			draft[externalStateProperty] = {
				...draft[externalStateProperty]!,
				questions: draft[externalStateProperty]!.questions.map(question => {
					return {
						...question,
						value: 0,
					};
				}),
			};
		}
		else if (externalStateProperty === "socialactivity") {
			draft[externalStateProperty] = {
				...draft[externalStateProperty]!,
				questions: draft[externalStateProperty]!.questions.map(question => {
					return {
						...question,
						value: 0,
					};
				}),
			};
		};
	};
};

function resetExternalState(draft: Draft<FunctionalsDraftType>) {
	resetExternalStateProperty(draft, "diet");
	resetExternalStateProperty(draft, "KATZADL");
	resetExternalStateProperty(draft, "LBIADL");
	resetExternalStateProperty(draft, "physicalactivity");
	resetExternalStateProperty(draft, "socialactivity");
};

function functionalsReducer(draft: Draft<FunctionalsDraftType>, action: FunctionalsActionType) {
	switch (action.type) {
		// case 'changeInitState': {
		// 	const narrowedAction = action as FunctionalsInitActionType;
		// 	// draft[narrowedAction.value] = propertyInits[narrowedAction.value] as KATZADLType;
		// 	break;
		// }
		case 'setExternalStateToSubmittedState': {
			const narrowedAction = action as FunctionalsSetNewStateActionType;
			parseZodReaderFriendly(ZodFunctionalsDraftType, narrowedAction.value);
			return narrowedAction.value;
			// draft.KATZADL!.activities = narrowedAction.value.KATZADL!.activities.map(activity => {
			// 	return {
			// 		...activity,
			// 	};
			// });
			// parseZodReaderFriendly(ZodFunctionalsDraftType, draft);

			// // console.log("functionalsReducer: ", draft.KATZADL!.activities.map(a => a.checked))
			// break;
		}
		case 'clearStateCauseDialogClosedNotSubmitted': {
			resetExternalState(draft);
			break;
		}
		case 'setCheckbox': {
			const narrowedAction = action as FunctionalsCheckboxActionType;
			draft.checked = narrowedAction.value;
			resetExternalState(draft);
			break;
		}
		case 'switchActivity': {
			const narrowedAction = action as FunctionalsSwitchActivityActionType;
			draft.KATZADL!.activities = draft.KATZADL!.activities.map(activity => {
				if (activity.id === narrowedAction.id) {
					activity.checked = narrowedAction.value;
				};

				return activity;
			});

			// // resetExternalState(draft);
			break;
		}
		default: {
			return draft;
		}
	};
};

interface FunctionalsPhysicianProps {
	children: ReactNode
	testingInit?: FunctionalsDraftType,
};

// source (truncate the link as necessary if broken): 
// sdop pdf = https://www.aafp.org/dam/AAFP/documents/patient_care/everyone_project/patient-short-print.pdf
// adl and iadl source = https://www.ncbi.nlm.nih.gov/books/NBK470404/?report=reader#:~:text=housekeeping%2C%20and%20laundry.-,Basic%20ADLs,to%20and%20from%20the%20toilet%2C%20use%20it%20appropriately%2C%20and%20clean%20oneself,-.
export function FunctionalsProvider({
	children,
	testingInit,
}: FunctionalsPhysicianProps) {
	const [functionals, dispatch] = useImmerReducer(functionalsReducer, testingInit ? testingInit : init as FunctionalsDraftType);

	return (
		<FunctionalsContext.Provider value={functionals}>
			<FunctionalsDispatchContext.Provider value={dispatch}>
				{children}
			</FunctionalsDispatchContext.Provider>
		</FunctionalsContext.Provider>
	);
};
