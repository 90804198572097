import {
	FormLabel,
	FormControl,
	FormGroup,
	Grid,
} from '@mui/material';

import { useGroupScreenings } from 'src/components/screenings/GroupScreeningsContext';
import { useSingleScreenings } from 'src/components/screenings/SingleScreeningsContext';
import { useToggledScreenings } from 'src/components/screenings/ToggledScreeningsContext';
import { usePhysicalInformation } from 'src/components/physical/physicalinformation/PhysicalInformationContext';

import { GroupedScreening } from './GroupedScreening';
import { SingleScreening } from './SingleScreening';
import { ToggledScreening } from './ToggledScreening';

export function Screenings(
) {
	const allGroupScreenings = useGroupScreenings();
	const allToggleScreenings = useToggledScreenings();
	const allSingleScreenings = useSingleScreenings();
	const physicalInformation = usePhysicalInformation();

	return (
		<FormControl
			sx={{
				// marginLeft: 9,
				// paddingBottom: 20,
				// height: "50%",
				overflow: "auto",
				// height: "400px",
				width: "100%"
			}}
			component="fieldset"
			variant="standard"
		>
			<FormLabel
				component="legend"
			>
				Choose screenings:
			</FormLabel>
			<FormGroup>
				{allSingleScreenings
					.filter(f => (
						f.id === "Diabetes" ||
						f.id === "Lipid Panel"
					))
					.filter(f => f.gender === undefined || f.gender === physicalInformation.filter(n => n.id === "patientGender")[0].value)
					.map(sScreening => {
						return (
							<Grid
								key={sScreening.id}
								item
							>
								<SingleScreening
									// key={sScreening.id}
									screening={sScreening}
								/>
							</Grid>
						);
					})}
				{allGroupScreenings
					// .filter(f => f.gender === undefined || f.gender === coverPage.patientGender.value)
					.map(gScreening => {
						return (
							<Grid
								key={gScreening.id}
								item
							>
								<GroupedScreening
									// key={gScreening.id}
									group={gScreening}
								/>
							</Grid>
						);
					})}
				{allSingleScreenings
					.filter(f => (
						f.id === "Mammogram" ||
						f.id === "DEXA Scan" ||
						f.id === "Hepatitis C (1945-1965)" ||
						f.id === "Lung Cancer (CT)"
					))
					.filter(f => f.gender === undefined || f.gender === physicalInformation.filter(n => n.id === "patientGender")[0].value)
					.map(sScreening => {
						return (
							<Grid
								key={sScreening.id}
								item
							>
								<SingleScreening
									// key={sScreening.id}
									screening={sScreening}
								/>
							</Grid>
						);
					})}
				{allToggleScreenings
					.filter(f => f.gender === undefined || f.gender === physicalInformation.filter(n => n.id === "patientGender")[0].value)
					.map(tScreening => {
						return (
							<Grid
								key={tScreening.id}
								item
							>
								<ToggledScreening
									// key={tScreening.id}
									screening={tScreening}
								/>
							</Grid>
						);
					})}
				{allSingleScreenings
					.filter(f => (
						f.id === "PSA" ||
						f.id === "Vision/Glaucoma" ||
						f.id === "Depression screening (PHQ-9)" ||
						f.id === "Annual Wellness Exam"
					))
					.filter(f => f.gender === undefined || f.gender === physicalInformation.filter(n => n.id === "patientGender")[0].value)
					.map(sScreening => {
						return (
							<Grid
								key={sScreening.id}
								item
							>
								<SingleScreening
									// key={sScreening.id}
									screening={sScreening}
								/>
							</Grid>
						);
					})}
			</FormGroup>
		</FormControl>
	);
};
