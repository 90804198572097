import { createContext, Dispatch, ReactNode, useContext, useEffect } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import { MedicationsDraftType, MedicationByDoseWholeType, MedicationByDosePartsType, MedicationNotByDoseType } from "src/types";
import { ZodMedicationsDraftType } from "src/types_zod";

import base from 'src/public/medications/medications.json';

parseZodReaderFriendly(ZodMedicationsDraftType, base);

const init = base as MedicationsDraftType;

export type MedicationsBrokenDownIndivType = {
    id: string;
    checked: boolean;
    label: string;
};

export type MedicationsBrokenDownDraftType = MedicationsBrokenDownIndivType[];

export const MedicationsContext = createContext<MedicationsBrokenDownDraftType | null>(null);
export const MedicationsDispatchContext = createContext<Dispatch<MedicationsActionType> | null>(null);

function breakDownMedications(medications: MedicationsDraftType): MedicationsBrokenDownDraftType {
	return medications.map(medication => {
		switch (medication.type) {
			case "bydose_whole": {
				const filteredMedication = medication as MedicationByDoseWholeType;
				const constructedId = `${filteredMedication.id}_${filteredMedication.amount}`;
				const constructedLabel = `${filteredMedication.id} ${filteredMedication.amount}${filteredMedication.unit}`;
				return {
					id: constructedId,
					checked: medication.checked,
					label: constructedLabel,
				};
			}
			case "bydose_parts": {
				const filteredMedication = medication as MedicationByDosePartsType;
				const constructedId = `${filteredMedication.id}_${filteredMedication.amount1}_${filteredMedication.amount2}`;
				const constructedLabel = `${filteredMedication.id} ${filteredMedication.amount1}/${filteredMedication.amount2}${filteredMedication.unit}`;
				return {
					id: constructedId,
					checked: medication.checked,
					label: constructedLabel,
				};
			}
			case "notbydose": {
				const filteredMedication = medication as MedicationNotByDoseType;
				const constructedId = `${filteredMedication.id}`;
				return {
					id: constructedId,
					checked: medication.checked,
					label: constructedId,
				};
			}
			default: {
				throw Error(`Medication type should either by bydose_whole, bydose_parts, or notbydose not ${medication.type}!`);
			};
		};
	});
};

export function useMedications() {
	return useContext(MedicationsContext)!;
};

export function useMedicationsDispatch() {
	return useContext(MedicationsDispatchContext)!;
};

export interface MedicationsInitActionType {
	type: string;
	value: MedicationsBrokenDownDraftType;
};

export interface MedicationsCheckboxActionType {
	type: string,
	id: string,
	value: boolean,
};

export type MedicationsActionType =
	| MedicationsInitActionType
	| MedicationsCheckboxActionType;

function medicationsReducer(draft: Draft<MedicationsBrokenDownDraftType>, action: MedicationsActionType) {
	switch (action.type) {
		case 'initialize': {
			const narrowedAction = action as MedicationsInitActionType;
			const copiedNarrowedActionValue = [...narrowedAction.value];

			copiedNarrowedActionValue.sort((a: MedicationsBrokenDownIndivType, b: MedicationsBrokenDownIndivType) => {
				if (a.label < b.label) {
					return -1;
				}
				if (a.label > b.label) {
					return 1;
				}

				return 0;
			});

			return copiedNarrowedActionValue;
		}
		case 'setCheckbox': {
			const narrowedAction = action as MedicationsCheckboxActionType;
			const physicianType = draft.filter(n => n.label === narrowedAction.id)[0];
			physicianType.checked = narrowedAction.value;
			break;
		}
		default: {
			return draft;
		};
	};
};

interface MedicationsPhysicianProps {
	children: ReactNode;
	testingInit?: MedicationsDraftType;
};

export function MedicationsProvider({
	children,
	testingInit,
}: MedicationsPhysicianProps) {
	// const [medications, dispatch] = useImmerReducer(medicationsReducer, { testingInit: testingInit, init: init}, createInitialState);
	const [medications, dispatch] = useImmerReducer(medicationsReducer,  testingInit ? breakDownMedications(testingInit) : breakDownMedications(init as MedicationsDraftType));
	// const [medications, dispatch] = useImmerReducer(medicationsReducer, MEDICATIONS);

	// useEffect(() => {
	// 	let ignore = false;
	// 	import(
	// 		/* webpackChunkName: "medications" */
	// 		`../../public/medications/medications.json`
	// 	)
	// 		.then(({ default: myJson }: { default: MedicationsDraftType }) => {
	// 			if (!ignore) {
	// 				const brokenDownMedications = breakDownMedications(myJson);
	// 				dispatch({
	// 					type: "initialize",
	// 					value: brokenDownMedications as MedicationsBrokenDownDraftType
	// 				});
	// 			};
	// 		})
	// 		.catch(err => console.log("err: ", err));

	// 	return () => {
	// 		ignore = true;
	// 	};
	// }, [dispatch]);

	return (
		<MedicationsContext.Provider value={medications}>
			<MedicationsDispatchContext.Provider value={dispatch}>
				{children}
			</MedicationsDispatchContext.Provider>
		</MedicationsContext.Provider>
	);
};
