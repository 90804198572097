import React from 'react';
import ReactDOM from 'react-dom/client';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

import App from './App';
import './index.css';

// this resolves ts error when trying to access
//		defineplugin variable MODE
declare const MODE: string;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		{MODE === "production" ?
			<CacheProvider
				value={createCache({
					key: 'my-prefix',
					// nonce: cspNonce !== null ? cspNonce : undefined, 
					nonce: document.querySelector('meta[property="csp-nonce"]')!.getAttribute("content") ?? undefined // nonce attribute requires undefined not null
				})}
			>
				<App />
			</CacheProvider> :
			<App />
		}
	</React.StrictMode>
);
