import { Dayjs } from "dayjs";
import { useMemo } from "react";

import { TimeField, TimeValidationError } from "@mui/x-date-pickers";
import { IoInputProps } from "src/types";
import { FieldChangeHandlerContext } from "@mui/x-date-pickers/internals";

interface ExternalIoTimePickerProps {
	label: string;
	value: Dayjs | null;
	readOnly?: boolean;
	setExternalState: (
		value: { value: Dayjs | null, validationError: string | null },
	) => void;
	id?: string;
};

export function ExternalIoTimePicker({
	label,
	value,
	readOnly,
	setExternalState,
	id,
}: ExternalIoTimePickerProps) {
	const inputProps: IoInputProps = useMemo(() => {
		return {
			"data-testid": `${id ? id : "lolz"}-timepicker`,
		};
	}, [id]);

	const onChange = (value: Dayjs | null, context: FieldChangeHandlerContext<TimeValidationError>) => {
		setExternalState({
			value: value,
			validationError: context.validationError,
		});
	};

	return (
		<TimeField
			inputProps={inputProps}
			label={label}
			value={value}
			size="small"
			readOnly={readOnly ? readOnly : false} // logically denotes readonly
			onChange={onChange}
			slotProps={{
				textField: {
					disabled: readOnly ? readOnly : false // visually denotes readonly
				}
			}}
		/>
	);
};
