import dayjs from "dayjs";
import { createContext, useContext, Dispatch, ReactNode, useEffect } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import { modifyDateAccordingToTest } from "src/io/wrappers/IoLinkedDates";
import { ToggledScreeningDraftType } from "src/types";
import { ZodToggledScreeningDraftType } from "src/types_zod";

import base from 'src/public/screenings/toggledscreenings.json';

parseZodReaderFriendly(ZodToggledScreeningDraftType, base);

const init = base as ToggledScreeningDraftType;

export const ToggledScreeningsContext = createContext<ToggledScreeningDraftType | null>(null);
export const ToggledScreeningsDispatchContext = createContext<Dispatch<ToggledScreeningActionType> | null>(null);

export function useToggledScreenings() {
	return useContext(ToggledScreeningsContext)!;
};

export function useToggledScreeningsDispatch() {
	return useContext(ToggledScreeningsDispatchContext)!;
};

export interface ToggledScreeningInitActionType {
	type: string;
	value: ToggledScreeningDraftType;
};

export interface ToggledScreeningCheckboxActionType {
	type: string;
	id: string;
	value: boolean;
	which: string;
};

export interface ToggledScreeningLinkCheckedActionType {
	type: string;
	id: string;
	value: boolean;
	parent: string;
};

export interface ToggledScreeningsToggleDateDueModifierActionType {
	type: string;
	id: string;
	value: string;
};

export interface ToggledScreeningDateDueActionType {
	type: string;
	id: string;
	value: string | null;
	parent: string;
};
export interface ToggledScreeningDateReceivedActionType {
	type: string;
	id: string;
	value: string | null;
	parent: string;
};
export interface ToggledScreeningDatePerformedActionType {
	type: string;
	id: string;
	value: string | null;
	parent: string;
};

export type ToggledScreeningActionType =
	ToggledScreeningInitActionType
	| ToggledScreeningCheckboxActionType
	| ToggledScreeningLinkCheckedActionType
	| ToggledScreeningsToggleDateDueModifierActionType
	| ToggledScreeningDateDueActionType
	| ToggledScreeningDateReceivedActionType
	| ToggledScreeningDatePerformedActionType;

function toggledScreeningsReducer(draft: Draft<ToggledScreeningDraftType>, action: ToggledScreeningActionType) {
	switch (action.type) {
		case 'initialize': {
			const narrowedAction = action as ToggledScreeningInitActionType;
			return narrowedAction.value;
		}
		case 'setCheckbox': {
			const narrowedAction = action as ToggledScreeningCheckboxActionType;
			const screening = draft.filter(n => n.id === narrowedAction.id)[0];
			switch (narrowedAction.which) {
				case "checked": {
					screening.checked = narrowedAction.value;
					screening.toggleButtonValue = screening.toggleOptions[0].id;
					screening.dateReceived = null;
					screening.dateDue = null;
					screening.dateUnknown = false;
					screening.datePerformed = null;
					break;
				}
				case "dateUnknown": {
					screening.dateUnknown = narrowedAction.value;
					screening.datePerformed = null;
					break;
				}
				default: {
					break;
				}
			};

			break;
		}
		case 'setLinkChecked': {
			const narrowedAction = action as ToggledScreeningLinkCheckedActionType;
			const screening = draft.filter(n => n.id === narrowedAction.id)[0];
			screening.linkChecked = narrowedAction.value;

			break;
		}
		case 'setDateReceived': {
			const narrowedAction = action as ToggledScreeningDateReceivedActionType;

			const screening = draft.filter(n => n.id === narrowedAction.id)[0];
			screening.dateReceived = narrowedAction.value;
			break;
		}
		case 'toggleDateDueModifier': {
			const narrowedAction = action as ToggledScreeningsToggleDateDueModifierActionType;

			const screening = draft.filter(n => n.id === narrowedAction.id)[0];
			screening.toggleButtonValue = narrowedAction.value;
			if (screening.toggleButtonValue === null && screening.dateReceived !== null) {
				const moddedVal = modifyDateAccordingToTest(narrowedAction.id, dayjs(screening.dateReceived), "screening");
				const val = moddedVal.format('MM/DD/YYYY')
				screening.dateDue = val;
			}
			else {
				screening.dateDue = null;
			};

			break;
		}
		case 'setDateDue': {
			const narrowedAction = action as ToggledScreeningDateDueActionType;

			const screening = draft.filter(n => n.id === narrowedAction.parent)[0];
			screening.dateDue = narrowedAction.value;
			break;
		}
		case 'setDatePerformed': {
			const narrowedAction = action as ToggledScreeningDatePerformedActionType;

			const screening = draft.filter(n => n.id === narrowedAction.parent)[0];
			screening.datePerformed = narrowedAction.value;
			break;
		}
		default: {
			return draft;
		}
	};
};

interface ToggledScreeningsPhysicianProps {
	children: ReactNode;
	testingInit?: ToggledScreeningDraftType;
};

export function ToggledScreeningsProvider({
	children,
	testingInit,
}: ToggledScreeningsPhysicianProps) {
	const [toggledScreenings, dispatch] = useImmerReducer(toggledScreeningsReducer, testingInit ? testingInit : init as ToggledScreeningDraftType);

	useEffect(() => {
		let ignore = false;
		import(
			/* webpackChunkName: "toggledscreenings" */
			`../../public/screenings/toggledscreenings.json`
		)
			.then(({ default: myJson }: { default: ToggledScreeningDraftType }) => {
				if (!ignore) {
					dispatch({
						type: "initialize",
						value: myJson
					});
				};
			})
			.catch(err => console.log("err: ", err));

		return () => {
			ignore = true;
		};
	}, [dispatch]);

	return (
		<ToggledScreeningsContext.Provider value={toggledScreenings}>
			<ToggledScreeningsDispatchContext.Provider value={dispatch}>
				{children}
			</ToggledScreeningsDispatchContext.Provider>
		</ToggledScreeningsContext.Provider>
	);
};
