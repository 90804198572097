import { useMemo, useState } from 'react';
import {
	GroupedScreeningIndivType,
	ToggleScreeningIndivType,
	SingleScreeningIndivType,
	RadioOptionsIndivType,
	BothVaccineIndivType,
	OnlyVaccineIndivType,
	DosesVaccineIndivType,
} from 'src/types';

interface ValidityInitialStateObj {
	dateReceived: boolean;
	dateDue: boolean;
	parent: undefined;
	child: undefined;
};

interface ValidityStateObj {
	dateReceived: boolean;
	dateDue: boolean;
	parent: string;
	child: string;
};
export function useValidityCheck(
	group?: GroupedScreeningIndivType,
	screening?: ToggleScreeningIndivType | SingleScreeningIndivType | RadioOptionsIndivType,
	vaccine?: BothVaccineIndivType | OnlyVaccineIndivType | DosesVaccineIndivType,
): ValidityStateObj {
	const [validity, setValidity] = useState<ValidityInitialStateObj | ValidityStateObj>({
		dateReceived: false,
		dateDue: false,
		parent: undefined,
		child: undefined,
	});

	useMemo(() => {
		if (vaccine === undefined) {
			if (group === undefined) {
				if (screening !== undefined) {
					const constrainedScreening = screening as ToggleScreeningIndivType | SingleScreeningIndivType;
					setValidity({
						dateReceived: constrainedScreening.checked,
						dateDue: (constrainedScreening.checked) &&
							(constrainedScreening.toggleButtonValue === null),
						parent: constrainedScreening.id,
						child: constrainedScreening.id,
					})
				}
				else {
					throw Error("All three (vaccine, group, screening) should NEVER be undefined")
				};
			}
			else {
				const constrainedScreening = screening as RadioOptionsIndivType;
				setValidity({
					dateReceived: (group.radioButtonValue === constrainedScreening.id),
					dateDue: (group.checked) &&
						(constrainedScreening.toggleButtonValue === null),
					parent: group.id,
					child: constrainedScreening.id,
				})
			};
		}
		else if (screening === undefined && group === undefined) {
			setValidity({
				dateReceived: vaccine.checked,
				dateDue: vaccine.checked,
				parent: vaccine.id,
				child: vaccine.id,
			});
		}
		else {
			throw Error("useValidityCheck should always have a defined object.")
		};
	}, [group, screening, vaccine])

	return validity as ValidityStateObj;
};
