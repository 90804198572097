import {
	FormLabel,
	FormControl,
	FormGroup,
	Grid,
} from '@mui/material';

import { useBothVaccines } from 'src/components/physical/vaccines/BothVaccinesContext';
import { useDosesVaccines } from 'src/components/physical/vaccines/DosesVaccinesContext';
import { useOnlyVaccines } from 'src/components/physical/vaccines/OnlyVaccinesContext';
import { usePneumococcalVaccine } from './PneumococcalVaccine/PneumococcalVaccineContext';
// import { useCoverPage } from 'src/data/static/CoverPageContext';

import { BothVaccine } from './BothVaccine';
import { DosesVaccine } from './DosesVaccine';
import { OnlyVaccine } from './OnlyVaccine';
import { PneumococcalVaccine } from './PneumococcalVaccine/PneumococcalVaccine';
import { useEffect } from 'react';
// import { useEffect } from 'react';

export function Vaccines(
) {
	const pneumococcalVaccine = usePneumococcalVaccine();

	const allDosesVaccines = useDosesVaccines();
	const allBothVaccines = useBothVaccines();
	const allOnlyVaccines = useOnlyVaccines();
	// const coverPage = useCoverPage();

	// useEffect(() => {
	// 	console.log("dateReceived: ", allBothVaccines.filter(a => a.id === "Influenza")[0].dateReceived);
	// }, [allBothVaccines]);
	// useEffect(() => {
	// 	console.log("dateDue: ", allBothVaccines.filter(a => a.id === "Influenza")[0].dateDue);
	// }, [allBothVaccines]);
	return (
		// <>
		<FormControl
			sx={{
				// marginLeft: 9,
				// paddingBottom: 20,
				// height: "50%",
				overflow: "auto",
				// height: "400px",
				width: "100%"
			}}
			component="fieldset"
			variant="standard"
		>
			<FormLabel
				component="legend"
			>
				Choose vaccines:
			</FormLabel>
			<FormGroup>
				<Grid
					key={pneumococcalVaccine!.id}
					item
				>
					<PneumococcalVaccine
						vaccine={pneumococcalVaccine}
					// dateOfBirth={Number(coverPage.filter(n => n.id === "dateOfBirth")[0].value)}
					/>
				</Grid>
				{allBothVaccines.map(bVaccine => {
					return (
						<Grid
							key={bVaccine.id}
							item
						>
							<BothVaccine
								vaccine={bVaccine}
							/>
						</Grid>
					);
				})}
				{allOnlyVaccines.map(oVaccine => {
					return (
						<Grid
							key={oVaccine.id}
							item
						>
							<OnlyVaccine
								vaccine={oVaccine}
							/>
						</Grid>
					);
				})}
				{allDosesVaccines.map(dVaccine => {
					return (
						<Grid
							key={dVaccine.id}
							item
						>
							<DosesVaccine
								vaccine={dVaccine}
							/>
						</Grid>
					);
				})}
			</FormGroup>
		</FormControl>
		// </>
	);
};
