import { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import { Button, Grid, Stack } from '@mui/material';

import { resetLocalState } from './helpers';
import { PageKATZADL } from './PageKATZADL';
import { PageLBIADL } from './PageLBIADL';
import { PageDiet } from './PageDiet';
import { PagePhysicalActivity } from './PagePhysicalActivity';
import { PageSocialActivity } from './PageSocialActivity';

import { useFunctionals, useFunctionalsDispatch } from './FunctionalsContext';
import { IoModalOnFormControlLabelCheckbox } from 'src/io/wrappers/IoModalOnFormControlLabelCheckbox';

import { parseZodReaderFriendly } from 'src/io/errorhandling/ioZod';
import { ZodFunctionalsDraftType } from 'src/types_zod';
import { FunctionalsDraftType } from 'src/types';

function usePage(
	pageNum: number,
	localFunctionalsCopy: FunctionalsDraftType,
	handleDietQuestionChange: (value: number, id: string) => void,
	handleActivitySwitch: (event: ChangeEvent<HTMLInputElement>, checked: boolean, id: string) => void,
	handleToggleActivityOption: (
		event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
		localFunctionalsCopyActivityId: string,
		index: number,
	) => void,
	handlePhysicalActivityQuestionChange: (value: number, id: string) => void,
	handleSocialActivityQuestionChange: (value: number, id: string) => void,
) {
	if (pageNum === 1) {
		return (
			<PageDiet
				localFunctionalsCopyDiet={localFunctionalsCopy.diet!}
				handleDietQuestionChange={handleDietQuestionChange}
			/>
		);
	}
	else if (pageNum === 2) {
		return (
			<PageKATZADL
				localFunctionalsCopyKATZADL={localFunctionalsCopy.KATZADL!}
				handleActivitySwitch={handleActivitySwitch}
			/>
		);
	}
	else if (pageNum === 3) {
		return (
			<PageLBIADL
				localFunctionalsCopyLBIADL={localFunctionalsCopy.LBIADL!}
				handleToggleActivityOption={handleToggleActivityOption}
			/>
		);
	}
	else if (pageNum === 4) {
		return (
			<PagePhysicalActivity
				localFunctionalsCopyPhysicalActivity={localFunctionalsCopy.physicalactivity!}
				handlePhysicalActivityQuestionChange={handlePhysicalActivityQuestionChange}
			/>
		);
	}
	else { // pageNum === 5
		return (
			<PageSocialActivity
				localFunctionalsCopySocialActivity={localFunctionalsCopy.socialactivity!}
				handleSocialActivityQuestionChange={handleSocialActivityQuestionChange}
			/>
		);
	}
};

interface FunctionalsProps {
	// functionals: FunctionalsDraftType;
	// age: number;
};

// eslint-disable-next-line no-empty-pattern
export function Functionals({
	// functionals,
}: FunctionalsProps) {
	const functionals = useFunctionals();
	const [localFunctionalsCopy, setLocalFunctionalsCopy] = useState<FunctionalsDraftType>(() => {
		if (functionals.KATZADL === null) {
			throw Error("KATZADL was null!")
		}
		else if (functionals.LBIADL === null) {
			throw Error("LBIADL was null!")
		}
		return functionals;
	});

	const [pageNum, setPageNum] = useState<number>(1);
	const [open, setOpen] = useState<boolean>(false);
	const [submitted, setSubmitted] = useState<boolean>(false);
	const dispatchFunctionals = useFunctionalsDispatch();

	const handleDietQuestionChange = (
		value: number,
		id: string, // questionid
	) => {
		// setLocalFunctionalsCopy(prev => {
		if (functionals.KATZADL === null) {
			throw Error("prev.KATZADL should never be null at this point of program.");
		}
		if (functionals.LBIADL === null) {
			throw Error("prev.LBIADL should never be null at this point of program.");
		}
		else {
			const tempFunctionals = structuredClone(functionals);


			tempFunctionals.diet!.questions.forEach(question => {
				if (question.id === id) {
					question.value = Number(value);
				};

			});

			dispatchFunctionals({
				type: "setExternalStateToSubmittedState",
				value: tempFunctionals,
			});
		};
		// })
	};

	const handleActivitySwitch = useCallback(
		(_event: ChangeEvent<HTMLInputElement>, checked: boolean, id: string) => {
			// console.log(`onHandleChange, checked: `, checked);
			// setLocalFunctionalsCopy(prev => {
			if (functionals.KATZADL === null) {
				throw Error("prev.KATZADL should never be null at this point of program.");
			}
			if (functionals.LBIADL === null) {
				throw Error("prev.LBIADL should never be null at this point of program.");
			}
			else {
				const tempFunctionals = structuredClone(functionals);
				let tempTotal: number = 0;

				tempFunctionals.KATZADL!.activities.forEach(activity => {
					if (activity.id === id) {
						activity.checked = checked;
					};
					tempTotal = tempTotal + (activity.checked ? 1 : 0);
				});
				tempFunctionals.KATZADL!.total = tempTotal;
				dispatchFunctionals({
					type: "setExternalStateToSubmittedState",
					value: tempFunctionals,
				});
			};
			// })
		},
		[
			functionals,
			dispatchFunctionals,
		],
	);

	const handleToggleActivityOption = useCallback((
		_event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
		localFunctionalsCopyActivityId: string,
		index: number,
	) => {
		if (functionals.KATZADL === null) {
			throw Error("localFunctionalsCopy.KATZADL in handleToggleActivityOption should never be null at this point of program.");
		}
		if (functionals.LBIADL === null) {
			throw Error("localFunctionalsCopy.LBIADL in handleToggleActivityOption should never be null at this point of program.");
		}
		else {
			const temp = structuredClone(functionals);
			temp.LBIADL!.activities.forEach(activity => {
				if (activity.id === localFunctionalsCopyActivityId) {
					const temp1 = activity.options.map((option, localIndex) => {
						if (localIndex === index) {
							return {
								...option,
								checked: !option.checked,
							};
						}
						else {
							return {
								...option,
								checked: false,
							};
						}
					});
					activity.options = temp1;
				}
				// console.log("temp1: ", temp1.map(t => t.checked));
			})
			dispatchFunctionals({
				type: "setExternalStateToSubmittedState",
				value: temp,
			});
		}
	},
		[
			functionals,
			dispatchFunctionals,
		]
	);

	const handlePhysicalActivityQuestionChange = (
		value: number,
		id: string, // questionid
	) => {
		// setLocalFunctionalsCopy(prev => {
			if (functionals.KATZADL === null) {
				throw Error("prev.KATZADL should never be null at this point of program.");
			}
			if (functionals.LBIADL === null) {
				throw Error("prev.LBIADL should never be null at this point of program.");
			}
			else {
				const tempFunctionals = structuredClone(functionals);


				tempFunctionals.physicalactivity!.questions.forEach(question => {
					if (question.id === id) {
						question.value = Number(value);
					};

				});

				dispatchFunctionals({
					type: "setExternalStateToSubmittedState",
					value: tempFunctionals,
				});
			};
		// })
	};

	const handleSocialActivityQuestionChange = (
		value: number,
		id: string, // questionid
	) => {
		// setLocalFunctionalsCopy(prev => {
			if (functionals.KATZADL === null) {
				throw Error("prev.KATZADL should never be null at this point of program.");
			}
			if (functionals.LBIADL === null) {
				throw Error("prev.LBIADL should never be null at this point of program.");
			}
			else {
				const tempFunctionals = structuredClone(functionals);


				tempFunctionals.socialactivity!.questions.forEach(question => {
					if (question.id === id) {
						question.value = Number(value);
					};

				});

				dispatchFunctionals({
					type: "setExternalStateToSubmittedState",
					value: tempFunctionals,
				});
			};
		// })
	};

	const page = usePage(
		pageNum,
		functionals,
		handleDietQuestionChange,
		handleActivitySwitch,
		handleToggleActivityOption,
		handlePhysicalActivityQuestionChange,
		handleSocialActivityQuestionChange,
	);

	const handleCheckbox = useCallback(
		(_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
			parseZodReaderFriendly(ZodFunctionalsDraftType, localFunctionalsCopy);
			dispatchFunctionals({
				type: "setCheckbox",
				value: checked,
			});
			setSubmitted(false);
			setLocalFunctionalsCopy(prev => resetLocalState(prev));
		},
		[dispatchFunctionals, localFunctionalsCopy]
	);

	const handleClose = useCallback(
		(_event: object, reason: "backdropClick" | "escapeKeyDown" | "submit") => {
			parseZodReaderFriendly(ZodFunctionalsDraftType, localFunctionalsCopy);
			if (reason === "submit") {
				const temp = structuredClone(localFunctionalsCopy);
				// const temp = createNewExternalState(tempPneumococcalVaccine, coverPageDateOfBirth, vaccine.ageRange);

				dispatchFunctionals({
					type: "setExternalStateToSubmittedState",
					value: temp as FunctionalsDraftType,
				});
				setSubmitted(true);
			}
			else {
				dispatchFunctionals({
					type: "clearStateCauseDialogClosedNotSubmitted",
				});
				setLocalFunctionalsCopy(prev => resetLocalState(prev));
				setSubmitted(false);
			};

			setOpen(false);
		},
		[
			localFunctionalsCopy,
			dispatchFunctionals,
		]
	);

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		if ((event.target as HTMLElement).textContent === "Back") {
			if (pageNum > 1) {
				setPageNum(prev => prev - 1);
			};
		}
		else if ((event.target as HTMLElement).textContent === "Next") {
			if (pageNum < 5) {
				setPageNum(prev => prev + 1);
			};
		}
		else if ((event.target as HTMLElement).textContent === "Submit") {
			handleClose(event, "submit");
		}
		else if ((event.target as HTMLElement).textContent === "functionals") {
			setOpen(true);
		};
	};

	return (
		<Grid
			id="functionals-toplevelgrid"
			item
			container
		>
			<Grid
				item
				sx={{
					overflow: "auto"
				}}
			>
				{page}
			</Grid>
			<Grid
				item
				container
			>
				<Grid
					item
				>
					<Button
						onClick={handleClick}
						variant="contained"
					>
						Back
					</Button>
				</Grid>
				<Grid
					item
				>
					<Button
						data-testid="nextButton"
						onClick={handleClick}
						variant="contained"
					>
						Next
					</Button>
				</Grid>
				<Grid
					item
				>
					<Button
						data-testid="submitButton"
						onClick={handleClick}
						variant="contained"
					>
						Submit
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};
