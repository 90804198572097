import { createContext, Dispatch, ReactNode, useContext, useEffect } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import { MedicationAllergiesDraftType } from "src/types";
import { ZodMedicationAllergiesDraftType } from "src/types_zod";

import base from 'src/public/medications/medicationallergies.json';

parseZodReaderFriendly(ZodMedicationAllergiesDraftType, base);

const init = base as MedicationAllergiesDraftType;

export const MedicationAllergiesContext = createContext<MedicationAllergiesDraftType | null>(null);
export const MedicationAllergiesDispatchContext = createContext<Dispatch<MedicationAllergiesActionType> | null>(null);

export function useMedicationAllergies() {
	return useContext(MedicationAllergiesContext)!;
};

export function useMedicationAllergiesDispatch() {
	return useContext(MedicationAllergiesDispatchContext)!;
};

export interface MedicationAllergiesInitActionType {
	type: string;
	value: MedicationAllergiesDraftType;
};

export interface MedicationAllergiesCheckboxActionType {
	type: string,
	id: string,
	value: boolean,
};

export type MedicationAllergiesActionType =
	| MedicationAllergiesInitActionType
	| MedicationAllergiesCheckboxActionType;

function medicationAllergiesReducer(draft: Draft<MedicationAllergiesDraftType>, action: MedicationAllergiesActionType) {
	switch (action.type) {
		case 'initialize': {
			const narrowedAction = action as MedicationAllergiesInitActionType;
			return narrowedAction.value;
		}
		case 'setCheckbox': {
			const narrowedAction = action as MedicationAllergiesCheckboxActionType;
			const medicationAllergy = draft.filter(n => n.value === narrowedAction.id)[0];
			medicationAllergy.checked = narrowedAction.value;
			break;
		}
		default: {
			return draft;
		};
	};
};

interface MedicationAllergiesPhysicianProps {
	children: ReactNode;
	testingInit?: MedicationAllergiesDraftType,
};

export function MedicationAllergiesProvider({
	children,
	testingInit,
}: MedicationAllergiesPhysicianProps) {
	const [medicationAllergies, dispatch] = useImmerReducer(medicationAllergiesReducer, testingInit ? testingInit : init as MedicationAllergiesDraftType);
	// const [medications, dispatch] = useImmerReducer(medicationsReducer, MEDICATIONS);

	// useEffect(() => {
	// 	let ignore = false;
	// 	import(
	// 		/* webpackChunkName: "medications" */
	// 		`../../public/medications/medicationallergies.json`
	// 	)
	// 		.then(({ default: myJson }: { default: MedicationAllergiesDraftType }) => {
	// 			if (!ignore) {
	// 				dispatch({
	// 					type: "initialize",
	// 					value: myJson,
	// 				});
	// 			};
	// 		})
	// 		.catch(err => console.log("err: ", err));

	// 	return () => {
	// 		ignore = true;
	// 	};
	// }, [dispatch]);

	return (
		<MedicationAllergiesContext.Provider value={medicationAllergies}>
			<MedicationAllergiesDispatchContext.Provider value={dispatch}>
				{children}
			</MedicationAllergiesDispatchContext.Provider>
		</MedicationAllergiesContext.Provider>
	);
};
