import { ChangeEvent, SyntheticEvent, useCallback, useEffect } from 'react';
import {
	Grid,
} from '@mui/material';

import { useAppointments, useAppointmentsDispatch } from './AppointmentsContext';

import { IoDatePicker } from 'src/io/shells/IoDatePicker';
import { IoTimePicker } from 'src/io/shells/IoTimePicker';
import { ExternalIoDatePicker } from 'src/io/shells/ExternalIoDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { ExternalIoTimePicker } from 'src/io/shells/ExternalIoTimePicker';

interface CoverPageProps {
	// mode: string,
};

// eslint-disable-next-line no-empty-pattern
export function Appointments({
	// mode,
}: CoverPageProps) {
	const appointments = useAppointments();
	const dispatchAppointments = useAppointmentsDispatch();

	const handleAppointmentDateChange = (
		value: {
			value: Dayjs | null;
			validationError: string | null;
		},
		id: string,
		key: string,
	) => {
		dispatchAppointments({
			type: "setAppointmentDateChange",
			id: id,
			value: value.value === null ? null : value.value.format('MM/DD/YYYY'),
			key: key
		});
	};

	const handleAppointmentTimeChange = (
		value: {
			value: Dayjs | null;
			validationError: string | null;
		},
		id: string,
		key: string,
	) => {
		dispatchAppointments({
			type: "setAppointmentTimeChange",
			id: id,
			value: value.value === null ? null : value.value.format("hh:mm A"),
			key: key
		});
	};

	useEffect(() => {
		console.log(`${appointments.filter(a => a.id === "Dr Olga Appointment")[0].time}`)
	}, [appointments])

	return (
		<Grid
			id="appointments-toplevelgrid"
			sx={{
				alignContent: "flex-start"
			}}
			item
			container
		>
			<Grid
				id="appointments-toplevelgrid-item-level1-drappointments"
				item
			>
				<ExternalIoDatePicker
					id="Dr Olga Appointment"
					label="Dr. Olga"
					value={dayjs(appointments.filter(a => a.id === "Dr Olga Appointment")[0].date)}
					setExternalState={(value: {
						value: Dayjs | null;
						validationError: string | null;
					}) => handleAppointmentDateChange(value, "Dr Olga Appointment", "date")}
				/>
				<ExternalIoTimePicker
					id="Dr Olga Appointment"
					label="Dr. Olga"
					value={dayjs(appointments.filter(a => a.id === "Dr Olga Appointment")[0].time, "hh:mm A")}
					setExternalState={(value: {
						value: Dayjs | null;
						validationError: string | null;
					}) => handleAppointmentTimeChange(value, "Dr Olga Appointment", "time")}
				/>
			</Grid>
			<Grid
				id="appointments-toplevelgrid-item-level1-labappointments"
				item
			>
				<ExternalIoDatePicker
					id="Lab Appointment"
					label="Lab"
					value={dayjs(appointments.filter(a => a.id === "Lab Appointment")[0].date)}
					setExternalState={(value: {
						value: Dayjs | null;
						validationError: string | null;
					}) => handleAppointmentDateChange(value, "Lab Appointment", "date")}
				/>
				<ExternalIoTimePicker
					id="Lab Appointment"
					label="Lab"
					value={dayjs(appointments.filter(a => a.id === "Lab Appointment")[0].time, "hh:mm A")}
					setExternalState={(value: {
						value: Dayjs | null;
						validationError: string | null;
					}) => handleAppointmentTimeChange(value, "Lab Appointment", "time")}
				/>
			</Grid>
		</Grid >
	);
};
