import { MouseEvent, useState } from "react";
import {
	ListItemButton,
	ListItemText,
	Collapse,
	ListItem,
	ListItemIcon,
	Checkbox,
} from "@mui/material";

import { LBIADLActivityOptionsType } from "src/types";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

interface PageLBIADLNestedListProps {
	localFunctionalsCopyActivityId: string;
	localFunctionalsCopyActivityOptions: LBIADLActivityOptionsType;
	handleToggleActivityOption: (
		event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
		localFunctionalsCopyActivityId: string,
		index: number,
	) => void;
};

export function PageLBIADLNestedList({
	localFunctionalsCopyActivityId,
	localFunctionalsCopyActivityOptions,
	handleToggleActivityOption,
}: PageLBIADLNestedListProps) {
	const [open, setOpen] = useState<boolean>(false);
	// useEffect(() => {
	// 	console.log(`activity.checked: `, localFunctionalsCopyActivity.checked);
	// }, [localFunctionalsCopyActivity]);

	const handleClick = () => {
		setOpen(!open);
	};


	return (
		<>
			<ListItemButton
				data-testid={`${localFunctionalsCopyActivityId}-listbutton`}
				onClick={handleClick}
			>
				<ListItemText
					primary={localFunctionalsCopyActivityId}
				/>
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				{localFunctionalsCopyActivityOptions.map((option, index) => {
					return (
						<ListItem
							key={`${localFunctionalsCopyActivityId}-${index}`}
							disablePadding
						>
							<ListItemButton
								role={undefined}
								onClick={(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) =>
									handleToggleActivityOption(event, localFunctionalsCopyActivityId, index)}
								dense
							>
								<ListItemIcon>
									<Checkbox
										edge="start"
										checked={option.checked}
										tabIndex={-1}
										disableRipple
									// inputProps={{ 'aria-labelledby': labelId }}
									/>
								</ListItemIcon>
								<ListItemText
									id={`option-${index + 1}`}
									primary={option.description}
								/>
							</ListItemButton>
						</ListItem>
					);
				})}
			</Collapse>
		</>
	);
};
