import { createContext, Dispatch, ReactNode, useContext } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import { OnlyVaccineDraftType } from "src/types";
import { ZodOnlyVaccineDraftType } from "src/types_zod";

import base from 'src/public/physical/vaccines/onlyvaccines.json';

parseZodReaderFriendly(ZodOnlyVaccineDraftType, base);

const init = base as OnlyVaccineDraftType;

export const OnlyVaccinesContext = createContext<OnlyVaccineDraftType | null>(null);
export const OnlyVaccinesDispatchContext = createContext<Dispatch<OnlyVaccinesActionType> | null>(null);

export function useOnlyVaccines() {
	return useContext(OnlyVaccinesContext)!;
};

export function useOnlyVaccinesDispatch() {
	return useContext(OnlyVaccinesDispatchContext)!;
};

export interface OnlyVaccineInitActionType {
	type: string;
	value: OnlyVaccineDraftType;
};

export interface OnlyVaccinesCheckboxActionType {
	type: string;
	id: string;
	value: boolean;
};

export interface OnlyVaccinesDateReceivedActionType {
	type: string;
	id: string;
	value: string | null;
};

export type OnlyVaccinesActionType =
	OnlyVaccineInitActionType
	| OnlyVaccinesCheckboxActionType
	| OnlyVaccinesDateReceivedActionType;

function onlyVaccinesReducer(draft: Draft<OnlyVaccineDraftType>, action: OnlyVaccinesActionType) {
	switch (action.type) {
		case 'initialize': {
			const narrowedAction = action as OnlyVaccineInitActionType;
			return narrowedAction.value;
		}
		case 'setCheckbox': {
			const narrowedAction = action as OnlyVaccinesCheckboxActionType;

			const vaccine = draft.filter(n => n.id === narrowedAction.id)[0];
			vaccine.checked = narrowedAction.value;
			vaccine.dateReceived = null;
			break;
		}
		case 'setDateReceived': {
			const narrowedAction = action as OnlyVaccinesDateReceivedActionType;

			const vaccine = draft.filter(n => n.id === narrowedAction.id)[0];
			vaccine.dateReceived = narrowedAction.value;
			break;
		}
		default: {
			break;
		}
	};
};

interface OnlyVaccinesPhysicianProps {
	children: ReactNode
	testingInit?: OnlyVaccineDraftType,
};

export function OnlyVaccinesProvider({
	children,
	testingInit,
}: OnlyVaccinesPhysicianProps) {
	const [onlyVaccines, dispatch] = useImmerReducer(onlyVaccinesReducer, testingInit ? testingInit : init as OnlyVaccineDraftType);

	// useEffect(() => {
	// 	let ignore = false;
	// 	import(
	// 		/* webpackChunkName: "onlyvaccines" */
	// 		`../../../public/physical/vaccines/onlyvaccines.json`
	// 	)
	// 		.then(({ default: myJson }: { default: OnlyVaccineDraftType }) => {
	// 			if (!ignore) {
	// 				dispatch({
	// 					type: "initialize",
	// 					value: myJson
	// 				});
	// 			};
	// 		})
	// 		.catch(err => console.log("err: ", err));

	// 	return () => {
	// 		ignore = true;
	// 	};
	// }, [dispatch]);

	return (
		<OnlyVaccinesContext.Provider value={onlyVaccines}>
			<OnlyVaccinesDispatchContext.Provider value={dispatch}>
				{children}
			</OnlyVaccinesDispatchContext.Provider>
		</OnlyVaccinesContext.Provider>
	);
};
