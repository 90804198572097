import { Draft } from "immer";
import { useImmerReducer } from "use-immer";
import { createContext, ReactNode, useContext, Dispatch } from "react";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import { PhysicalInformationDraftType, PhysicalInformationIndivType } from "src/types";
import { ZodPhysicalInformationDraftType } from "src/types_zod";

import base from 'src/public/physical/physicalinformation/physicalinformation.json';

parseZodReaderFriendly(ZodPhysicalInformationDraftType, base);

const init = base as PhysicalInformationDraftType;

export const PhysicalInformationContext = createContext<PhysicalInformationDraftType | null>(null);
export const PhysicalInformationDispatchContext = createContext<Dispatch<PhysicalInformationActionType> | null>(null);

export function usePhysicalInformation() {
	return useContext(PhysicalInformationContext)!;
};

export function usePhysicalInformationDispatch() {
	return useContext(PhysicalInformationDispatchContext)!;
};

export interface PhysicalInformationInitActionType {
	type: string;
	value: PhysicalInformationDraftType;
};

export interface PhysicalInformationNumActionType {
	type: string;
	id: string,
	value: number;
};

export type PhysicalInformationActionType =
	| PhysicalInformationInitActionType
	| PhysicalInformationNumActionType;

function physicalInformationReducer(draft: Draft<PhysicalInformationDraftType>, action: PhysicalInformationActionType) {
	switch (action.type) {
		case 'changeInitState': {
			const narrowedAction = action as PhysicalInformationInitActionType;
			return narrowedAction.value;
		}
		case 'setNumChange': {
			const narrowedAction = action as PhysicalInformationNumActionType;
			const physicalInformationItem = draft.filter(n => n.id === narrowedAction.id)[0] as PhysicalInformationIndivType;

			physicalInformationItem.value = narrowedAction.value;
			break;
		}
		default: {
			return draft;
		}
	};
};

interface PhysicalInformationPhysicianProps {
	children: ReactNode,
	testingInit?: PhysicalInformationDraftType,
};

export function PhysicalInformationProvider({
	children,
	testingInit,
}: PhysicalInformationPhysicianProps) {
	const [physicalInformation, dispatch] = useImmerReducer(physicalInformationReducer, testingInit ? testingInit : init as PhysicalInformationDraftType);

	return (
		<PhysicalInformationContext.Provider value={physicalInformation}>
			<PhysicalInformationDispatchContext.Provider value={dispatch}>
				{children}
			</PhysicalInformationDispatchContext.Provider>
		</PhysicalInformationContext.Provider>
	);
};
