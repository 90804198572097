import { createContext, Dispatch, ReactNode, useContext, useEffect } from "react";
import { Draft } from "immer";
import { useImmerReducer } from "use-immer";

import { parseZodReaderFriendly } from "src/io/errorhandling/ioZod";

import { BothVaccineDraftType } from "src/types";
import { ZodBothVaccineDraftType } from "src/types_zod";

import base from 'src/public/physical/vaccines/bothvaccines.json';

parseZodReaderFriendly(ZodBothVaccineDraftType, base);

const init = base as BothVaccineDraftType;

export const BothVaccinesContext = createContext<BothVaccineDraftType | null>(null);
export const BothVaccinesDispatchContext = createContext<Dispatch<BothVaccinesActionType> | null>(null);

export function useBothVaccines() {
	return useContext(BothVaccinesContext)!;
};

export function useBothVaccinesDispatch() {
	return useContext(BothVaccinesDispatchContext)!;
};

export interface BothVaccineInitActionType {
	type: string;
	value: BothVaccineDraftType;
};

export interface BothVaccineCheckboxActionType {
	type: string;
	id: string;
	value: boolean;
};

export interface BothVaccineDateReceivedActionType {
	type: string;
	id: string;
	value: string | null;
	parent: string;
};

export interface BothVaccineDateDueActionType {
	type: string;
	id: string;
	value: string | null;
	parent: string;
};

export interface BothVaccineLinkCheckedActionType {
	type: string;
	id: string;
	value: boolean;
	parent: string;
};

export type BothVaccinesActionType =
	BothVaccineInitActionType
	| BothVaccineCheckboxActionType
	| BothVaccineDateReceivedActionType
	| BothVaccineDateDueActionType
	| BothVaccineLinkCheckedActionType;


function bothVaccinesReducer(draft: Draft<BothVaccineDraftType>, action: BothVaccinesActionType) {
	switch (action.type) {
		case 'initialize': {
			const narrowedAction = action as BothVaccineInitActionType;
			return narrowedAction.value;
		}
		case 'setCheckbox': {
			const narrowedAction = action as BothVaccineCheckboxActionType;

			const vaccine = draft.filter(n => n.id === narrowedAction.id)[0];
			vaccine.checked = narrowedAction.value;
			vaccine.dateReceived = null;
			vaccine.dateDue = null;
			break;
		}
		case 'setDateReceived': {
			const narrowedAction = action as BothVaccineDateReceivedActionType;

			const vaccine = draft.filter(n => n.id === narrowedAction.id)[0];
			vaccine.dateReceived = narrowedAction.value;
			// if (narrowedAction.id === "Tetanus") {
			// 	vaccine.dateDue = dayjs(narrowedAction.value).add(10, "year").format('MM/DD/YYYY');
			// }
			// else {
			// vaccine.dateDue = dayjs(narrowedAction.value).add(1, "year").format('MM/DD/YYYY');
			// };
			break;

		}
		case 'setDateDue': {
			const narrowedAction = action as BothVaccineDateDueActionType;

			const vaccine = draft.filter(n => n.id === narrowedAction.id)[0];
			vaccine.dateDue = narrowedAction.value;
			break;
		}
		case 'setLinkChecked': {
			const narrowedAction = action as BothVaccineLinkCheckedActionType;

			const vaccine = draft.filter(n => n.id === narrowedAction.id)[0];
			vaccine.linkChecked = narrowedAction.value;
			break;
		}
		default: {
			break;
		}
	};
};

interface BothVaccinesPhysicianProps {
	children: ReactNode
	testingInit?: BothVaccineDraftType,
};

export function BothVaccinesProvider({
	children,
	testingInit,
}: BothVaccinesPhysicianProps) {
	const [bothVaccines, dispatch] = useImmerReducer(bothVaccinesReducer, testingInit ? testingInit : init as BothVaccineDraftType);

	// useEffect(() => {
	// 	let ignore = false;
	// 	import(
	// 		/* webpackChunkName: "bothvaccines" */
	// 		`../../../public/physical/vaccines/bothvaccines.json`
	// 	)
	// 		.then(({ default: myJson }: { default: BothVaccineDraftType }) => {
	// 			if (!ignore) {
	// 				dispatch({
	// 					type: "initialize",
	// 					value: myJson
	// 				});
	// 			};
	// 		})
	// 		.catch(err => console.log("err: ", err));

	// 	return () => {
	// 		ignore = true;
	// 	};
	// }, [dispatch]);

	return (
		<BothVaccinesContext.Provider value={bothVaccines}>
			<BothVaccinesDispatchContext.Provider value={dispatch}>
				{children}
			</BothVaccinesDispatchContext.Provider>
		</BothVaccinesContext.Provider>
	);
};
